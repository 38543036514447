import React from 'react';
import PowerliftingPage from '../components/competitive-powerlifting/PowerliftingPage';

const CompetitivePowerlifting = () => {
  return (
    <div> {/* Remove padding here */}
      <PowerliftingPage />
    </div>
  );
};

export default CompetitivePowerlifting;