import React from 'react';
import { useViewportScaling } from '../hooks/useViewportScaling';

const Dexa = () => {
  const { scale } = useViewportScaling();

  const containerStyle = {
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: '#333333',
    borderRadius: `${12 * scale}px`,
    width: `${800 * scale}px`,
    height: `${300 * scale}px`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  };

  const textStyle = {
    fontSize: `${80 * scale}px`,
    fontFamily: 'sans-serif',
    color: '#CCCCCC',
    textAlign: 'center',
  };

  return (
    <div style={containerStyle}>
      <p style={textStyle}>This page is currently empty.</p>
    </div>
  );
};

export default Dexa;