import React from 'react';

const CardioFitnessTable = () => {
  const tableData = {
    women: [
      { age: '18-19', low: '<10.0', belowAverage: '10-11.0', aboveAverage: '11.1-12.9', high: '13-14.9', elite: '≥15.0' },
      { age: '20-29', low: '<8.0', belowAverage: '8.0-9.9', aboveAverage: '10-11.4', high: '11.5-14.2', elite: '≥14.3' },
      { age: '30-39', low: '<7.7', belowAverage: '7.7-9.3', aboveAverage: '9.4-10.8', high: '10.9-13.6', elite: '≥13.7' },
      { age: '40-49', low: '<7.4', belowAverage: '7.4-8.9', aboveAverage: '9.0-10.3', high: '10.4-13.2', elite: '≥13.3' },
      { age: '50-59', low: '<7.0', belowAverage: '7.0-8.0', aboveAverage: '8.1-9.9', high: '10.0-12.9', elite: '≥13.0' },
      { age: '60-69', low: '<6.0', belowAverage: '6.0-6.9', aboveAverage: '7.0-8.4', high: '8.5-11.0', elite: '≥11.1' },
      { age: '70-79', low: '<5.0', belowAverage: '5.0-5.9', aboveAverage: '6.0-6.9', high: '7.0-9.9', elite: '≥10.0' },
      { age: '≥80', low: '<4.4', belowAverage: '4.4-5.4', aboveAverage: '5.5-6.2', high: '6.3-8.3', elite: '≥8.4' },
    ],
    men: [
      { age: '18-19', low: '<10.8', belowAverage: '10.8-12.9', aboveAverage: '13.0-13.9', high: '14-16.2', elite: '≥16.3' },
      { age: '20-29', low: '<10.3', belowAverage: '10.3-11.9', aboveAverage: '12.0-13.6', high: '13.7-15.6', elite: '≥15.7' },
      { age: '30-39', low: '<10.0', belowAverage: '10-11.1', aboveAverage: '11.2-12.9', high: '13.0-14.9', elite: '≥15.0' },
      { age: '40-49', low: '<9.8', belowAverage: '9.8-10.9', aboveAverage: '11.0-12.4', high: '12.5-14.6', elite: '≥14.7' },
      { age: '50-59', low: '<8.2', belowAverage: '8.2-9.9', aboveAverage: '10.0-11.3', high: '11.4-13.9', elite: '≥14.0' },
      { age: '60-69', low: '<7.0', belowAverage: '7.0-8.4', aboveAverage: '8.5-9.9', high: '10.0-12.9', elite: '≥13.0' },
      { age: '70-79', low: '<6.0', belowAverage: '6.0-6.9', aboveAverage: '7.0-8.4', high: '8.5-11.4', elite: '≥11.5' },
      { age: '≥80', low: '<5.1', belowAverage: '5.1-6.2', aboveAverage: '6.3-7.2', high: '7.3-9.9', elite: '≥10.0' },
    ]
  };

  const tableStyle = {
    width: '100%',
    borderCollapse: 'collapse',
    color: '#CCCCCC',
  };

  const headerStyle = {
    textAlign: 'center',
    paddingBottom: '5px',
    paddingTop: '5px',
    borderBottom: '1px solid rgba(204, 204, 204, 0.5)',
    fontSize: '1.1em',
    width: '16.66%',
  };

  const cellStyle = {
    padding: '3px 10px',
    borderBottom: '1px solid rgba(204, 204, 204, 0.5)',
    textAlign: 'center',
  };

  const titleStyle = {
    fontSize: '1.5em',
    marginBottom: '15px',
    fontWeight: 'bold',
    color: '#CCCCCC',
  };

  const descriptionStyle = {
    fontSize: '1em',
    marginTop: '15px',
    lineHeight: '1.4',
    color: '#CCCCCC',
  };

  const citationStyle = {
    fontSize: '0.9em',
    marginBottom: '15px',
    lineHeight: '1.4',
    fontStyle: 'italic',
    color: '#CCCCCC',
  };

  const renderTableRows = (data, gender) => (
    <>
      <tr>
        <td colSpan="6" style={{ ...cellStyle, fontWeight: 'bold', textAlign: 'left', paddingLeft: '0' }}>{gender}</td>
      </tr>
      {data.map((row, index) => (
        <tr key={`${gender}-${index}`}>
          <td style={cellStyle}>{row.age}</td>
          <td style={cellStyle}>{row.low}</td>
          <td style={cellStyle}>{row.belowAverage}</td>
          <td style={cellStyle}>{row.aboveAverage}</td>
          <td style={cellStyle}>{row.high}</td>
          <td style={cellStyle}>{row.elite}</td>
        </tr>
      ))}
    </>
  );

  return (
    <div style={{ color: '#CCCCCC', padding: '20px', backgroundColor: '#111111' }}>
      <p style={citationStyle}>
        Authors: Mandsager K, Harb S, Cremer P, Phelan D, Nissen SE, Jaber W<br />
        Title: "Association of Cardiorespiratory Fitness With Long-term Mortality Among Adults Undergoing Exercise Treadmill Testing"<br />
        Find At: JAMA Netw Open. 2018;1(6):e183605. doi:10.1001/jamanetworkopen.2018.3605
      </p>
      <h2 style={titleStyle}>Classification of Cardiorespiratory Fitness by Age and Sex</h2>
      <table style={tableStyle}>
        <thead>
          <tr>
            <th style={headerStyle}>Age, y</th>
            <th style={headerStyle}>Low</th>
            <th style={headerStyle}>Below Average</th>
            <th style={headerStyle}>Above Average</th>
            <th style={headerStyle}>High</th>
            <th style={headerStyle}>Elite</th>
          </tr>
        </thead>
        <tbody>
          {renderTableRows(tableData.women, 'Women')}
          {renderTableRows(tableData.men, 'Men')}
        </tbody>
      </table>
      <p style={descriptionStyle}>
        Ranges are given in metabolic equivalents, with 1 metabolic equivalent equaling 3.5 mL/kg per minute of oxygen consumption. 
        Classification (percentile range) is as follows: low (&lt;25th percentile), below average (25th-49th percentile), 
        above average (50th-74th percentile), high (75th-97.6th percentile), and elite (≥97.7th percentile).
      </p>
    </div>
  );
};

export default CardioFitnessTable;