import React, { useState, useEffect } from 'react';
import {
  AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, ReferenceLine
} from 'recharts';
import { useViewportScaling } from '../../hooks/useViewportScaling';

const rawData = [
  { age: 18, Low: [0, 38.0], BelowAverage: [38.0, 45.4], AboveAverage: [45.4, 48.9], High: [48.9, 56.9], Elite: [56.9, 70], ageRange: null },
  { age: 18.5, Low: [0, 37.8], BelowAverage: [37.8, 45.2], AboveAverage: [45.2, 48.7], High: [48.7, 56.7], Elite: [56.7, 70], ageRange: '18-19' },
  { age: 24.5, Low: [0, 36.1], BelowAverage: [36.1, 41.7], AboveAverage: [41.7, 47.6], High: [47.6, 54.6], Elite: [54.6, 70], ageRange: '20-29' },
  { age: 34.5, Low: [0, 35.0], BelowAverage: [35.0, 38.9], AboveAverage: [38.9, 45.2], High: [45.2, 52.2], Elite: [52.2, 70], ageRange: '30-39' },
  { age: 44.5, Low: [0, 34.3], BelowAverage: [34.3, 38.2], AboveAverage: [38.2, 43.4], High: [43.4, 51.1], Elite: [51.1, 70], ageRange: '40-49' },
  { age: 54.5, Low: [0, 28.7], BelowAverage: [28.7, 34.7], AboveAverage: [34.7, 39.6], High: [39.6, 48.7], Elite: [48.7, 70], ageRange: '50-59' },
  { age: 64.5, Low: [0, 24.5], BelowAverage: [24.5, 29.4], AboveAverage: [29.4, 34.7], High: [34.7, 45.2], Elite: [45.2, 70], ageRange: '60-69' },
  { age: 74.5, Low: [0, 21.0], BelowAverage: [21.0, 24.2], AboveAverage: [24.2, 29.4], High: [29.4, 39.9], Elite: [39.9, 70], ageRange: '70-79' },
  { age: 85.0, Low: [0, 17.9], BelowAverage: [17.9, 21.7], AboveAverage: [21.7, 25.2], High: [25.2, 34.7], Elite: [34.7, 70], ageRange: '≥80' },
  { age: 90, Low: [0, 16.4], BelowAverage: [16.4, 19.9], AboveAverage: [19.9, 23.1], High: [23.1, 31.8], Elite: [31.8, 70], ageRange: null }
];

const processData = (rawData) => {
  return rawData.map(item => ({
    age: item.age,
    ageRange: item.ageRange,
    Low: item.Low[1],
    BelowAverage: item.BelowAverage[1] - item.Low[1],
    AboveAverage: item.AboveAverage[1] - item.BelowAverage[1],
    High: item.High[1] - item.AboveAverage[1],
    Elite: item.Elite[1] - item.High[1]
  }));
};

const data = processData(rawData);

const referenceLines = [
  { y: 7, label: 'showering, toweling off (standing)' },
  { y: 56, label: 'Running, 10mph (6 min/mile)' },
  { y: 28, label: 'Running, 5mph (12 min/mile)' },
  { y: 3.15, label: 'Sleeping' },
  { y: 43.75, label: 'Running, 7.5 mph (8 min/mile)' },
  { y: 10.5, label: 'Walking downstairs' },
  { y: 8.75, label: 'Walking 2.0 mph, level, slow pace, firm surface' },
  { y: 12.25, label: 'Walking 3.0 mph, level, moderate pace, firm surface' },
  { y: 14, label: 'Walking 4.0 mph, level, firm suface, very brisk pace' },
  { y: 17.5, label: 'Walking 3.0 mph carying 1-15lb load upstairs' }
];

const LegendItem = ({ color, text, scale }) => (
  <div style={{ display: 'flex', flexWrap: 'nowrap', alignItems: 'center', marginRight: `${20 * scale}px` }}>
    <div style={{ width: `${20 * scale}px`, height: `${20 * scale}px`, backgroundColor: color, marginRight: `${5 * scale}px` }}></div>
    <span style={{ color: '#CCCCCC', fontSize: `${30 * scale}px` }}>{text}</span>
  </div>
);

const CustomLegend = ({ scale }) => (
  <div style={{ display: 'flex', flexWrap: 'nowrap', justifyContent: 'center', marginBottom: `${20 * scale}px` }}>
    <LegendItem color="#FF0000" text="Low (25th percentile)" scale={scale} />
    <LegendItem color="#FF8000" text="Below Average (25th-49th percentile)" scale={scale} />
    <LegendItem color="#FFD700" text="Above Average (50th-74th percentile)" scale={scale} />
    <LegendItem color="#ADFF2F" text="High (75th-97.6th percentile)" scale={scale} />
    <LegendItem color="#00FF00" text="Elite (≥97.7th percentile)" scale={scale} />
  </div>
);

const VO2MaxChart = () => {
  const { scale } = useViewportScaling();
  const [isLoaded, setIsLoaded] = useState(false);

  const chartStyle = {
    position: 'absolute',
    width: `${3000 * scale}px`,
    height: `${1300 * scale}px`,
    left: `50%`,
    top: `53%`,
    transform: `translate(-50%, -50%)`,
    opacity: isLoaded ? 1 : 0,
    transition: 'opacity 0.3s ease-in-out',
  };

  useEffect(() => {
    // Use requestAnimationFrame to ensure the chart is rendered after the initial layout
    requestAnimationFrame(() => {
      setIsLoaded(true);
    });
  }, []);

  const customActiveDot = (props) => {
    const { cx, cy, stroke, payload } = props;
    if (payload.age === 18 || payload.age === 90) {
      return null;
    }
    return (
      <circle cx={cx} cy={cy} r={4 * scale} stroke={stroke} fill={stroke} />
    );
  };

  return (
    <div className="chart-container" style={chartStyle}>
      <h2 style={{ textAlign: 'center', marginBottom: `${10 * scale}px`, fontWeight: 'bold', fontSize: `${50 * scale}px`, color: '#CCCCCC' }}>VO2 Max by Age Range and Fitness Level (Men)</h2>
      <CustomLegend scale={scale} />
      <ResponsiveContainer width="100%" height="90%">
        <AreaChart
          data={data}
          width={3000 * scale}
          height={1300 * scale}
          margin={{
            top: 10 * scale, right: 300 * scale, left: 10 * scale, bottom: 80 * scale,
          }}
        >
          <XAxis 
            dataKey="age" 
            type="number" 
            domain={[18, 90]} 
            ticks={[18, 25, 30, 40, 50, 60, 70, 80, 90]}
            label={{ value: 'Age', position: 'bottom', offset: 20 * scale, fill: '#CCCCCC', fontSize: `${40 * scale}px` }} 
            tick={{ fill: '#CCCCCC', fontSize: `${30 * scale}px` }} 
          />
          <YAxis 
            domain={[0, 70]} 
            label={{ value: 'VO2 Max (mL/kg per minute of oxygen consumption)', angle: -90, position: 'insideLeft', style: { textAnchor: 'middle' }, dy: 20 * scale, fill: '#CCCCCC', fontSize: `${40 * scale}px` }}
            tick={{ fill: '#CCCCCC', fontSize: `${30 * scale}px` }}
          />
          <CartesianGrid strokeDasharray="3 3" stroke="#CCCCCC" />
          <Tooltip content={<CustomTooltip scale={scale} />} />
          <Area type="monotone" dataKey="Low" stackId="1" stroke="#FF0000" fill="#FF0000" activeDot={customActiveDot} />
          <Area type="monotone" dataKey="BelowAverage" stackId="1" stroke="#FF8000" fill="#FF8000" activeDot={customActiveDot} />
          <Area type="monotone" dataKey="AboveAverage" stackId="1" stroke="#FFD700" fill="#FFD700" activeDot={customActiveDot} />
          <Area type="monotone" dataKey="High" stackId="1" stroke="#ADFF2F" fill="#ADFF2F" activeDot={customActiveDot} />
          <Area type="monotone" dataKey="Elite" stackId="1" stroke="#00FF00" fill="#00FF00" activeDot={customActiveDot} />
          {referenceLines.map((line, index) => (
            <ReferenceLine
              key={index}
              y={line.y}
              stroke="#CCCCCC"
              strokeDasharray="3 3"
              label={{
                value: line.label,
                position: 'right',
                fill: '#CCCCCC',
                fontSize: 20 * scale,
                style: { textAnchor: 'start' },
                offset: 20 * scale
              }}
            />
          ))}
        </AreaChart>
      </ResponsiveContainer>
    </div>
  );
}

const CustomTooltip = ({ active, payload, label, scale }) => {
  if (active && payload && payload.length) {
    // Check if the age is 18 or 90, and if so, return null (no tooltip)
    if (label === 18 || label === 90) {
      return null;
    }

    let ranges = {
      Low: [0, payload[0].value],
      BelowAverage: [payload[0].value, payload[0].value + payload[1].value],
      AboveAverage: [payload[0].value + payload[1].value, payload[0].value + payload[1].value + payload[2].value],
      High: [payload[0].value + payload[1].value + payload[2].value, payload[0].value + payload[1].value + payload[2].value + payload[3].value],
      Elite: [payload[0].value + payload[1].value + payload[2].value + payload[3].value, 70]
    };

    return (
      <div className="custom-tooltip" style={{ padding: `${10 * scale}px`, border: `${5 * scale}px solid #CCCCCC`, color: '#CCCCCC' }}>
        {payload[0].payload.ageRange && <p><strong>Age Range: {payload[0].payload.ageRange}</strong></p>}
        {Object.entries(ranges).map(([key, value], index) => (
          <p key={index} style={{ color: payload[index].color }}>
            {key}: {value[0].toFixed(1)} - {value[1].toFixed(1)}
          </p>
        ))}
      </div>
    );
  }
  return null;
};

export default VO2MaxChart;