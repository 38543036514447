import React, { useState, useEffect } from 'react';
import { useViewportScaling } from '../hooks/useViewportScaling';

const Gym = () => {
  const [isLoaded, setIsLoaded] = useState(false);
  const { scale, scaleH } = useViewportScaling();

  useEffect(() => {
    // Delay rendering to ensure all calculations are done
    const timer = setTimeout(() => {
      setIsLoaded(true);
    }, 100);

    return () => clearTimeout(timer);
  }, []);

  const containerStyle = {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    zIndex: 20,
    pointerEvents: 'none',
    opacity: isLoaded ? 1 : 0,
    transition: 'opacity 300ms ease-in-out',
  };

  const textStyle = {
    position: 'absolute',
    left: '50%',
    top: `${1300 * scaleH}px`,
    transform: 'translate(-50%, -50%)',
    textAlign: 'left',
    color: '#CCCCCC',
    fontSize: `${30 * scale}px`,
    fontFamily: 'Arial, sans-serif',
    lineHeight: `${60 * scale}px`,
    pointerEvents: 'auto',
  };

  const tableStyle = {
    width: `${3000 * scale}px`,
    borderCollapse: 'collapse',
  };

  const titleStyle = {
    textAlign: 'left',
    fontWeight: 'bold',
    paddingBottom: `${100 * scale}px`,
    verticalAlign: 'top',
    width: '100%',
  };

  const thStyle = {
    textAlign: 'left',
    fontWeight: 'bold',
    paddingBottom: `${40 * scale}px`,
    verticalAlign: 'top',
    width: '15%',
  };

  const tdStyle = {
    textAlign: 'left',
    paddingBottom: `${40 * scale}px`,
    verticalAlign: 'top',
    width: '85%',
  };

  if (!isLoaded) {
    return null; // Return null or a loading indicator while content is not ready
  }

  return (
    <div style={containerStyle}>
      <div style={textStyle}>
        <table style={tableStyle}>
          <thead>
            <tr>
              <th colSpan="2" style={titleStyle}>This section contains a summary of my gym stuff.</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td style={thStyle}>Competitive Powerlifting:</td>
              <td style={tdStyle}>
                I began going to the gym in February 2018 when I was 14. I remember the month specifically, because my friend had recently started going and had been encouraging me to join him.

                After a few years of training, my progression remained consistent and I became decently strong. Over the 3 years I powerlifted, I competed 3 times. 
                On this page, you can find videos of lifts from my powerlifting journey, including from the competitions.

                At the time of writing this, I am ranked 25th globally for my category according to Open Powerlifting (https://www.openpowerlifting.org/rankings/75/all-tested/men/16-17/by-total). At the time of my last competition on 10-Oct-2020, I was ranked 7th globally in my age and weight category.

                If I hadn't been red lighted for my second squat attempt, I would have broken the world record at the time, and would have moved up to 3rd in global rankings. And today, I would have been ranked 14th globally. Unfortunately, I was red lighted for this squat because I repositioned my feet after the down command. I was quite disappointed because I missed the world record squat for my category due to a technical error, rather than something to do with the squat itself. 
              </td>
            </tr>
            <tr>
              <td style={thStyle}>Bench Press:</td>
              <td style={tdStyle}>In July 2023, at 20 years old, I decided to rekindle some of my powerlifting training, however, with the specific goal of bench pressing double my bodyweight. Unlike my younger powerlifting days, I was particularly regimented about logging exactly what I did every single workout, and I have an Excel spreadsheet documenting every set, every exercise, and every gym session I have done since July 16. Although admittedly, unlike my younger powerlifting days, my training has been much less consistent, and less intense. Ultimately, I was able to bench double BW on the 9th September 2024 at 21 years old. On that day, my morning BW was 72.8kg, my evening BW was 73.3kg, and I benched 150kg.</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Gym;