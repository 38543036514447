import React, { useRef, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useViewportScaling } from '../hooks/useViewportScaling';

const Navbar = () => {
  const location = useLocation();
  const { scale, scaleW, scaleH } = useViewportScaling();
  const healthLinkRef = useRef(null);
  const gymLinkRef = useRef(null);

  const isHealthRoute = location.pathname.startsWith('/health');
  const isGymRoute = location.pathname.startsWith('/gym');

  const navStyle = {
    position: 'absolute',
    top: `${0 * scaleH}px`,
    left: `${0 * scaleW}px`,
    right: `${0 * scaleW}px`,
    padding: `${30 * scale}px`,
    zIndex: 30,
    backgroundColor: 'transparent',
  };

  const linkStyle = {
    color: '#CCCCCC',
    fontSize: `${60 * scale}px`,
    padding: `${15 * scale}px`,
    borderRadius: `${5 * scale}px`,
    textDecoration: 'none',
    transition: 'all 300ms',
  };

  const activeLinkStyle = {
    ...linkStyle,
    backgroundColor: '#333',
  };

  const containerStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  };

  const linkContainerStyle = {
    display: 'flex',
  };

  const spacerStyle = {
    width: `${50 * scaleW}px`,
  };

  useEffect(() => {
    // Dispatch custom events when the Health and Gym links are rendered
    if (healthLinkRef.current) {
      window.dispatchEvent(new CustomEvent('healthLinkRendered', { detail: healthLinkRef.current }));
    }
    if (gymLinkRef.current) {
      window.dispatchEvent(new CustomEvent('gymLinkRendered', { detail: gymLinkRef.current }));
    }
  }, []);

  return (
    <nav style={navStyle}>
      <div style={containerStyle}>
        <div style={linkContainerStyle}>
          <Link ref={healthLinkRef} to="/health" style={isHealthRoute ? activeLinkStyle : linkStyle}>Health</Link>
          <div style={spacerStyle}></div>
          <Link ref={gymLinkRef} to="/gym" style={isGymRoute ? activeLinkStyle : linkStyle}>Gym</Link>
          <div style={spacerStyle}></div>
          <Link to="/reading-list" style={location.pathname === '/reading-list' ? activeLinkStyle : linkStyle}>Reading List</Link>
        </div>
        <Link to="/" style={location.pathname === '/' ? activeLinkStyle : linkStyle}>Rourke Palmer</Link>
      </div>
    </nav>
  );
};

export default Navbar;