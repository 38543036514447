import React, { useState, useEffect } from 'react';
import { useViewportScaling } from '../hooks/useViewportScaling';

const Health = () => {
  const [isLoaded, setIsLoaded] = useState(false);
  const { scale, scaleH } = useViewportScaling();

  useEffect(() => {
    // Delay rendering to ensure all calculations are done
    const timer = setTimeout(() => {
      setIsLoaded(true);
    }, 100);

    return () => clearTimeout(timer);
  }, []);

  const containerStyle = {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    zIndex: 20,
    pointerEvents: 'none',
    opacity: isLoaded ? 1 : 0,
    transition: 'opacity 300ms ease-in-out',
  };

  const textStyle = {
    position: 'absolute',
    left: '50%',
    top: `${1300 * scaleH}px`,
    transform: 'translate(-50%, -50%)',
    textAlign: 'left',
    color: '#CCCCCC',
    fontSize: `${30 * scale}px`,
    fontFamily: 'Arial, sans-serif',
    lineHeight: `${60 * scale}px`,
    pointerEvents: 'auto',
  };

  const tableStyle = {
    width: `${3000 * scale}px`,
    borderCollapse: 'collapse',
  };

  const titleStyle = {
    textAlign: 'left',
    fontWeight: 'bold',
    paddingBottom: `${100 * scale}px`,
    verticalAlign: 'top',
    width: '100%',
  };

  const thStyle = {
    textAlign: 'left',
    fontWeight: 'bold',
    paddingBottom: `${40 * scale}px`,
    verticalAlign: 'top',
    width: '15%',
  };

  const tdStyle = {
    textAlign: 'left',
    paddingBottom: `${40 * scale}px`,
    verticalAlign: 'top',
    width: '85%',
  };

  if (!isLoaded) {
    return null; // Return null or a loading indicator while content is not ready
  }

  return (
    <div style={containerStyle}>
      <div style={textStyle}>
        <table style={tableStyle}>
          <thead>
            <tr>
              <th colSpan="2" style={titleStyle}>This section contains a summary of my health according to data that I've logged.</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td style={thStyle}>Blood Tests:</td>
              <td style={tdStyle}>I have taken three blood tests. The most comprehensive blood test was my most recent one. Most of my results are normal, however there are a handful of items I plan on working to improve.</td>
            </tr>
            <tr>
              <td style={thStyle}>Bodyweight:</td>
              <td style={tdStyle}>For over a year I have been logging my weight each time I step on the scale. I go through phases of consistency and inconsistency in weighing myself, however, overall the data is quite informative. Despite my diet changing quite drastically as I relocate to and from my home (in London) to university (in Los Angeles), as well as my exercise regime ebbing (sometimes woefully) and flowing, my bodyweight remains surprisingly consistent. The most impactful change to my bodyweight has been due to supplementing creatine monohydrate.</td>
            </tr>
            <tr>
              <td style={thStyle}>Metabolic:</td>
              <td style={tdStyle}>I have put together quite an interesting chart showing the (ballpark) required vo2max to achieve everyday activities, as well as the decline of vo2max with age and percentile. I intend to measure my vo2max to see where I fall on this chart, and project where I may end up as I get older. I have not done any form of consistent cardiovascular training since I stopped playing rugby when I was 17, but even if my vo2max score is poor, I hope to increase it by training such that even at an old age I will be capable of performing basic tasks. Later, I also want to get other metabolic information about myself such as my lactate profile, resting metabolic rate, and fat max test.</td>
            </tr>
            <tr>
              <td style={thStyle}>MRI:</td>
              <td style={tdStyle}>I've only done one MRI, and here are some of the image sequences taken. All my results were normal (as I expected). I did an MRI because I want to have a baseline collection of images and information about my healthy self, so if I become unhealthy later down the road, doctors can assess my health according to what I, me, looked like when I was healthy, rather than using standard images representing the average person. I hope this will enable me to get more personalized care when I need it, and ultimately increase my chance of receiving better treatment.</td>
            </tr>
            <tr>
              <td style={thStyle}>DEXA:</td>
              <td style={tdStyle}>I have not yet done a DEXA scan, so this page is currently empty.</td>
            </tr>
            <tr>
              <td style={thStyle}>Cognitive:</td>
              <td style={tdStyle}>Although the vast majority of this Health section focuses on data about my physical body, my ultimate goal is to extend my cognitive abilities for as long as possible. Cognitive performance and maintenance is heavily tied to your physical health. This page displays a chart of cognitive performance over time, and what I am aiming to achieve for my cognitive performance.</td>
            </tr>
            <tr>
              <td style={thStyle}>WHY?</td>
              <td style={tdStyle}>At 21, I feel incredibly fortunate to have a clear sense of my passion and purpose, something I believe many people struggle to find. My aspirations are ambitious, undoubtedly requiring a significant investment of cognitive ability, time, and effort. I am determined not only to extend my lifespan but also to preserve my cognitive sharpness for as long as possible, enabling me to pursue what I love at the highest level for the greatest length of time. It is not enough simply to live long; if I begin to experience cognitive decline at 60, I would have to step away from my life's work to avoid diminishing its quality. My goal is to enjoy both longevity and sustained cognitive excellence throughout my life, so I can continue to contribute meaningfully for as long as possible.</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Health;