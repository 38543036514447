import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './global.css';

import Navbar from './components/Navbar';
import Health from './pages/Health';
import Gym from './pages/Gym';
import ReadingList from './pages/ReadingList';
import RourkePalmer from './pages/RourkePalmer';
import BloodTests from './pages/BloodTests';
import Bodyweight from './pages/Bodyweight';
import Metabolic from './pages/Metabolic';
import Mri from './pages/Mri';
import Dexa from './pages/Dexa';
import Cognitive from './pages/Cognitive';
import HealthLayout from './components/metabolic/HealthLayout';
import GymLayout from './components/gym/GymLayout';
import CompetitivePowerlifting from './pages/CompetitivePowerlifting';
import BenchPress from './pages/BenchPress';

function App() {
  return (
    <Router>
      <div className="App">
        <Navbar />
        <Routes>
          <Route path="/health" element={<HealthLayout />}>
            <Route index element={<Health />} />
            <Route path="blood-tests" element={<BloodTests />} />
            <Route path="bodyweight" element={<Bodyweight />} />
            <Route path="metabolic" element={<Metabolic />} />
            <Route path="mri" element={<Mri />} />
            <Route path="dexa" element={<Dexa />} />
            <Route path="cognitive" element={<Cognitive />} />
          </Route>
          <Route path="/gym" element={<GymLayout />}>
            <Route index element={<Gym />} />
            <Route path="competitive-powerlifting" element={<CompetitivePowerlifting />} />
            <Route path="bench-press" element={<BenchPress />} />
          </Route>
          <Route path="/reading-list" element={<ReadingList />} />
          <Route path="/" element={<RourkePalmer />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;