import React, { useState, useRef, useMemo, useEffect, useCallback } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { useViewportScaling } from '../../hooks/useViewportScaling';
import PBsData from './PBsData';

const CustomTooltip = ({ active, payload, label }) => {
    const { scale } = useViewportScaling();
    const [videoSrc, setVideoSrc] = useState(null);
    const videoRef = useRef(null);
    const currentIdRef = useRef(null);
    const tooltipRef = useRef(null);
  
    const videoFiles = useMemo(() => ({
      '394': '11-Aug-24.mp4',
      '402': '19-Aug-24.mp4',
      '377': '25-Jul-24.mp4',
      '371': '19-Jul-24.mp4',
      '421': '7-Sep-24.mp4',
      '430': '16-Sep-24.mp4'
    }), []);
  
    const loadVideo = useCallback((id) => {
      if (videoFiles[id]) {
        import(`../../media/gym/bench-press/${videoFiles[id]}`)
          .then(module => {
            setVideoSrc(module.default);
          })
          .catch(error => {
            console.error(`Error loading video for id ${id}:`, error);
            setVideoSrc(null);
          });
      } else {
        setVideoSrc(null);
      }
    }, [videoFiles]);
  
    useEffect(() => {
      if (active && payload && payload.length) {
        const id = payload[0].payload.id;
        
        if (id !== currentIdRef.current) {
          currentIdRef.current = id;
          loadVideo(id);
        }
      } else {
        currentIdRef.current = null;
        setVideoSrc(null);
      }
    }, [active, payload, loadVideo]);
  
    useEffect(() => {
      const video = videoRef.current;
      if (video && videoSrc) {
        video.load();
        video.play().catch(e => console.error("Error playing video:", e));
      }
      return () => {
        if (video) {
          video.pause();
          video.currentTime = 0;
        }
      };
    }, [videoSrc]);
  
    if (active && payload && payload.length) {
      const { date, id } = payload[0].payload;
      const hasVideo = videoFiles.hasOwnProperty(id);

      const tooltipStyle = {
        backgroundColor: '#000000', 
        padding: `${10 * scale}px`, 
        border: `${1 * scale}px solid #CCCCCC`,
        fontFamily: 'Arial, sans-serif',
        fontSize: `${30 * scale}px`,
        color: '#CCCCCC',
        zIndex: 1000,
        position: 'fixed',
        maxWidth: '90vw',
        maxHeight: '90vh',
        overflow: 'hidden',
        transition: 'none',
        left: '50%',
        top: '-3%',
        transform: 'translate(-50%, 0%)',
      };

      return (
        <div ref={tooltipRef} style={tooltipStyle}>
          <p>{`${date}`}</p>
          {payload.map((entry) => (
            <p key={entry.name} style={{ color: entry.color }}>
              {`${entry.name}: ${entry.value ? Math.round(entry.value) : 'N/A'} kg`}
            </p>
          ))}
          {hasVideo && videoSrc && (
            <video 
              ref={videoRef} 
              width={`${1500 * scale}px`} 
              height="auto" 
              controls 
              autoPlay 
              muted 
              playsInline
            >
              <source src={videoSrc} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          )}
        </div>
      );
    }
    return null;
};

const BenchPressChart = () => {
  const { scale } = useViewportScaling();
  const [showPoints, setShowPoints] = useState(true);
  const [showLines, setShowLines] = useState(true);

  const { combinedData, minId, maxId } = useMemo(() => {
    const allIds = new Set(PBsData.map(d => parseInt(d.id)));
    const minId = Math.min(...allIds);
    const maxId = Math.max(...allIds);
    
    const dataMap = {
      'For 1 rep': new Map(),
      'For 2 reps': new Map(),
      'For 3 reps': new Map(),
      'For 4 reps': new Map(),
      'For 5 reps': new Map(),
    };

    PBsData.forEach(d => {
      const key = `For ${d.reps} rep${d.reps > 1 ? 's' : ''}`;
      if (dataMap[key]) {
        dataMap[key].set(d.id, { weight: d.weight, date: d.date });
      }
    });
    
    const combinedData = Array.from(allIds).map(id => {
      const idStr = id.toString();
      return {
        id: idStr,
        date: PBsData.find(d => d.id === idStr)?.date,
        'For 1 rep': dataMap['For 1 rep'].get(idStr)?.weight || null,
        'For 2 reps': dataMap['For 2 reps'].get(idStr)?.weight || null,
        'For 3 reps': dataMap['For 3 reps'].get(idStr)?.weight || null,
        'For 4 reps': dataMap['For 4 reps'].get(idStr)?.weight || null,
        'For 5 reps': dataMap['For 5 reps'].get(idStr)?.weight || null,
      };
    }).sort((a, b) => parseInt(a.id) - parseInt(b.id));

    return { combinedData, minId, maxId };
  }, []);

  const lineColors = {
    'For 1 rep': '#4FC3F7',  // blue
    'For 2 reps': '#81C784',   // green
    'For 3 reps': '#D4E157',     // lime
    'For 4 reps': '#FFD54F',    // yellow
    'For 5 reps': '#FF8A65', // orange
  };

  const chartStyle = {
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    width: `${3000 * scale}px`,
    height: `${1000 * scale}px`,
  };

  const checkboxContainerStyle = {
    position: 'absolute',
    right: `${-20 * scale}px`,
    top: `${20 * scale}px`,
    zIndex: 1000,
    fontFamily: 'Arial, sans-serif',
    fontSize: `${24 * scale}px`,
    color: '#CCCCCC',
  };

  const checkboxStyle = {
    width: `${30 * scale}px`,
    height: `${30 * scale}px`,
    marginRight: `${10 * scale}px`,
  };

  const labelStyle = {
    display: 'flex',
    alignItems: 'center',
    fontFamily: 'Arial, sans-serif',
    fontSize: `${30 * scale}px`,
    marginBottom: `${30 * scale}px`,
  };

  const customXAxisTicks = [1, 32, 63, 93, 124, 154, 185, 216, 245, 276, 306, 337, 367, 398, 429];

  const formatXAxisTick = (tickItem) => {
    const tickToMonthMap = {
      1: 'Jul', 32: 'Aug', 63: 'Sep', 93: 'Oct', 124: 'Nov', 154: 'Dec',
      185: 'Jan', 216: 'Feb', 245: 'Mar', 276: 'Apr', 306: 'May', 337: 'Jun',
      367: 'Jul', 398: 'Aug', 429: 'Sep'
    };
    return tickToMonthMap[tickItem] || '';
  };

  return (
    <div style={chartStyle}>
      <div style={checkboxContainerStyle}>
        <label style={labelStyle}>
          <input
            type="checkbox"
            checked={showPoints}
            onChange={() => setShowPoints(!showPoints)}
            style={checkboxStyle}
          />
          <span>Points</span>
        </label>
        <label style={labelStyle}>
          <input
            type="checkbox"
            checked={showLines}
            onChange={() => setShowLines(!showLines)}
            style={checkboxStyle}
          />
          <span>Lines</span>
        </label>
      </div>
      <ResponsiveContainer width="100%" height="100%">
        <LineChart 
          data={combinedData} 
          margin={{ 
            top: 15 * scale, 
            right: 90 * scale, 
            left: 60 * scale, 
            bottom: 15 * scale 
          }}
        >
          <CartesianGrid strokeDasharray="3 3" stroke="#333333" horizontal={true} vertical={false} />
          <XAxis
            dataKey="id"
            type="number"
            domain={[minId, maxId]}
            ticks={customXAxisTicks}
            tickFormatter={formatXAxisTick}
            tick={{ fill: '#CCCCCC', fontSize: `${25 * scale}px` }}
            axisLine={{ stroke: '#CCCCCC' }}
            tickLine={false}
            padding={{ left: 30, right: 30 }}
            label={{ value: 'Month', position: 'bottom', fill: '#CCCCCC', fontSize: `${30 * scale}px` }}
          />
          <YAxis
            domain={[80, 150]}
            ticks={[80, 90, 100, 110, 120, 130, 140, 150]}
            tick={{ fill: '#CCCCCC', fontSize: `${25 * scale}px` }}
            label={{ value: 'Weight (kg)', angle: -90, position: 'insideLeft', fill: '#CCCCCC', dx: -10 * scale, dy: 40 * scale, fontSize: `${30 * scale}px` }}
          />
          <Tooltip content={<CustomTooltip />} />
          <Legend wrapperStyle={{ top: 1000 * scale, color: '#CCCCCC', fontSize: `${30 * scale}px` }} />
          {Object.entries(lineColors).map(([key, color]) => (
            <Line
              key={key}
              type="monotone"
              dataKey={key}
              stroke={color}
              strokeWidth={4 * scale}
              dot={showPoints ? { r: 6 * scale, strokeWidth: 4 * scale, fill: color } : false}
              activeDot={showPoints ? { r: 10 * scale, strokeWidth: 4 * scale, fill: color } : false}
              name={key}
              filter="url(#glow)"
              hide={false}
              strokeOpacity={showLines ? 1 : 0}
              connectNulls={true}
            />
          ))}
          <defs>
            <filter id="glow">
              <feGaussianBlur stdDeviation={2.5 * scale} result="coloredBlur" />
              <feMerge>
                <feMergeNode in="coloredBlur" />
                <feMergeNode in="SourceGraphic" />
              </feMerge>
            </filter>
          </defs>
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

export default BenchPressChart;