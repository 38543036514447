import React, { useState, useEffect } from 'react';
import CognitiveAgeChart from './CognitiveAgeChart';
import SourceCognitive from './SourceCognitive';
import { useViewportScaling } from '../../hooks/useViewportScaling';

const PageCognitive = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isSourceVisible, setIsSourceVisible] = useState(false);
  const { scaleH, scaleW } = useViewportScaling();

  useEffect(() => {
    if (isOpen) {
      // Delay making the source visible to ensure it's positioned correctly
      const timer = setTimeout(() => setIsSourceVisible(true), 50);
      return () => clearTimeout(timer);
    } else {
      setIsSourceVisible(false);
    }
  }, [isOpen]);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const dropdownStyle = {
    position: 'fixed',
    top: `${1750 * scaleH}px`,
    left: `${1920 * scaleW}px`,
    transform: 'translate(-50%, -50%)',
    zIndex: 30,
    width: `${3000 * scaleW}px`,
  };

  return (
    <div className="container mx-auto p-4" style={{ color: '#CCCCCC' }}>
      <div className="mb-4">
        <CognitiveAgeChart />
      </div>
      <div style={dropdownStyle}>
        <button
          onClick={toggleDropdown}
          className="w-full font-semibold py-2 px-4 rounded flex justify-between items-center"
          style={{ backgroundColor: '#111111', color: '#CCCCCC' }}
        >
          <span>Sources</span>
          <svg
            className={`w-4 h-4 transition-transform duration-200 ${isOpen ? 'transform rotate-90' : ''}`}
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5l7 7-7 7"></path>
          </svg>
        </button>
        {isOpen && <SourceCognitive isVisible={isSourceVisible} />}
      </div>
    </div>
  );
};

export default PageCognitive;