import React from 'react';
import DisplayMRI from '../components/mri/DisplayMRI';

const Mri = () => {
  return (
    <div className="container mx-auto p-4">
      <DisplayMRI />
    </div>
  );
};

export default Mri;