import React from 'react';
import BloodTestsTable from '../components/blood-tests/BloodTestsTable';

const BloodTests = () => {
  return (
    <div> {/* Remove padding here */}
      <BloodTestsTable />
    </div>
  );
};

export default BloodTests;