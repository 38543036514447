import React from 'react';
import ReadingListTable from '../components/reading-list/ReadingListTable';

const ReadingList = () => {
  return (
    <div className="h-screen overflow-hidden"> {}
      <ReadingListTable />
    </div>
  );
};

export default ReadingList;