import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useViewportScaling } from '../../hooks/useViewportScaling';

const ReadingList = () => {
  const [visibleBooks, setVisibleBooks] = useState([]);
  const [currentYear, setCurrentYear] = useState('2024');
  const [isLoaded, setIsLoaded] = useState(false);
  const { scale } = useViewportScaling();

  const tableData = useMemo(() => [
    { year: '2022', books: [
      { title: 'The Three-Body Problem', author: 'Liu Cixin' },
      { title: 'The Dark Forest', author: 'Liu Cixin' },
      { title: "Death's End", author: 'Liu Cixin' },
      { title: 'Dune', author: 'Frank Herbert' },
      { title: 'Dune Messiah', author: 'Frank Herbert' },
      { title: 'Children of Dune', author: 'Frank Herbert' },
      { title: 'God Emperor of Dune', author: 'Frank Herbert' },
      { title: 'Heretics of Dune', author: 'Frank Herbert' },
      { title: 'Dune Chapterhouse', author: 'Frank Herbert' },
    ]},
    { year: '2023', books: [
      { title: 'War and Peace', author: 'Leo Tolstoy' },
      { title: 'I, Robot', author: 'Isaac Asimov' },
      { title: 'Foundation', author: 'Isaac Asimov' },
      { title: 'Foundation and Empire', author: 'Isaac Asimov' },
    ]},
    { year: '2024', books: [
      { title: "Alice's Adventures in Wonderland", author: 'Lewis Carroll' },
      { title: 'Through the Looking Glass', author: 'Lewis Carroll' },
      { title: 'To Build a Fire, and Other Tales of the North', author: 'Jack London' },
      { title: 'Outlive', author: 'Peter Attia' },
      { title: 'Hyperion', author: 'Dan Simmons' },
      { title: 'The Fall of Hyperion', author: 'Dan Simmons' },
    ]},
  ], []);

  const updateVisibleBooks = useCallback(() => {
    const yearData = tableData.find(data => data.year === currentYear);
    if (yearData) {
      setVisibleBooks(yearData.books);
    }
  }, [currentYear, tableData]);

  useEffect(() => {
    updateVisibleBooks();
  }, [updateVisibleBooks]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoaded(true);
    }, 100);

    return () => clearTimeout(timer);
  }, []);

  const containerStyle = useMemo(() => ({
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    color: '#CCCCCC',
    padding: `${20 * scale}px`,
    backgroundColor: 'transparent',
    marginTop: `${150 * scale}px`,
    width: `${2000 * scale}px`,
    height: `${1000 * scale}px`,
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    opacity: isLoaded ? 1 : 0,
    transition: 'opacity 0.3s ease-in-out',
  }), [scale, isLoaded]);

  const tableStyle = useMemo(() => ({
    width: '100%',
    height: `${900 * scale}px`,
    borderCollapse: 'collapse',
    color: '#CCCCCC',
    overflow: 'auto',
  }), [scale]);

  const headerStyle = useMemo(() => ({
    textAlign: 'center',
    paddingBottom: `${30 * scale}px`,
    borderBottom: '1px solid rgba(204, 204, 204, 0.5)',
    fontSize: `${40 * scale}px`,
    position: 'sticky',
    top: 0,
    backgroundColor: '#000000',
  }), [scale]);

  const cellStyle = useMemo(() => ({
    padding: `${3 * scale}px ${10 * scale}px`,
    borderBottom: '1px solid rgba(204, 204, 204, 0.5)',
    textAlign: 'center',
    fontSize: `${40 * scale}px`,
  }), [scale]);

  const yearSelectorStyle = useMemo(() => ({
    display: 'flex',
    justifyContent: 'center',
    marginBottom: `${50 * scale}px`,
  }), [scale]);

  const yearButtonStyle = useMemo(() => ({
    backgroundColor: 'transparent',
    border: '1px solid #CCCCCC',
    color: '#CCCCCC',
    padding: `${10 * scale}px ${20 * scale}px`,
    margin: `0 ${20 * scale}px`,
    cursor: 'pointer',
    fontSize: `${50 * scale}px`,
  }), [scale]);

  const fixedColumnStyle = {
    width: '50%',
    textAlign: 'center',
  };

  return (
    <div style={containerStyle}>
      <div style={yearSelectorStyle}>
        {tableData.map(yearData => (
          <button
            key={yearData.year}
            style={{
              ...yearButtonStyle,
              backgroundColor: currentYear === yearData.year ? '#333' : 'transparent'
            }}
            onClick={() => setCurrentYear(yearData.year)}
          >
            {yearData.year}
          </button>
        ))}
      </div>
      <div style={tableStyle}>
        <table style={{ width: '100%', tableLayout: 'fixed' }}>
          <colgroup>
            <col style={fixedColumnStyle} />
            <col style={fixedColumnStyle} />
          </colgroup>
          <thead>
            <tr>
              <th style={headerStyle}>Title</th>
              <th style={headerStyle}>Author</th>
            </tr>
          </thead>
          <tbody>
            {visibleBooks.map((book, index) => (
              <tr key={index}>
                <td style={cellStyle}>{book.title}</td>
                <td style={cellStyle}>{book.author}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ReadingList;