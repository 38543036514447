import React, { useState, useEffect, useRef } from 'react';
import { useViewportScaling } from '../../hooks/useViewportScaling';

const videos = [
  { src: '10-Oct-20-1.mp4', title: '235kg' },
  { src: '10-Oct-20-2.mp4', title: '250kg (missed)' },
  { src: '10-Oct-20-3.mp4', title: '250kg (missed)' },
  { src: '10-Oct-20-4.mp4', title: '125kg' },
  { src: '10-Oct-20-5.mp4', title: '135kg' },
  { src: null, title: 'No attempt' },
  { src: '10-Oct-20-6.mp4', title: '205kg' },
  { src: '10-Oct-20-7.mp4', title: '225kg' },
  { src: '10-Oct-20-8.mp4', title: '235kg' }
];

const Video = ({ video, scale, onEnded, isPlaying }) => {
  const videoRef = useRef(null);

  useEffect(() => {
    if (isPlaying && videoRef.current) {
      videoRef.current.play().catch(e => console.error("Error playing video:", e));
    }
  }, [isPlaying]);

  if (!video.src) {
    return (
      <div style={{ textAlign: 'center' }}>
        {/* This div represents a placeholder for when no video is available */}
        {/* Width: 600px (scaled), Height: 338px (scaled) - likely representing a 1920:1080 aspect ratio */}
        <div style={{ width: `${600 * scale}px`, height: `${338 * scale}px`, backgroundColor: '#333', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <span style={{ color: '#CCCCCC' }}>No video available</span>
        </div>
        <div style={{ color: '#CCCCCC', marginTop: '5px' }}>{video.title}</div>
      </div>
    );
  }

  return (
    <div style={{ textAlign: 'center' }}>
      <video 
        ref={videoRef}
        // Width: 1000px (scaled) - This is the width for actual videos
        // Height: auto - maintains aspect ratio based on the video's original dimensions
        width={`${600 * scale}px`} 
        height="auto" 
        controls 
        muted 
        playsInline
        onEnded={onEnded}
      >
        <source src={require(`../../media/gym/powerlifting/${video.src}`)} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <div style={{ color: '#CCCCCC', marginTop: '5px' }}>{video.title}</div>
    </div>
  );
};

const CompGrid20202 = () => {
  const { scale } = useViewportScaling();
  const [currentVideoIndex, setCurrentVideoIndex] = useState(0);

  const handleVideoEnded = () => {
    setCurrentVideoIndex((prevIndex) => (prevIndex + 1) % videos.length);
  };

  return (
    <div style={{ display: 'grid', gridTemplateColumns: 'repeat(4, 1fr)', gap: `${20 * scale}px`, width: `${2500 * scale}px` }}>
      <div></div>
      <div style={{ textAlign: 'center', fontWeight: 'bold', color: '#CCCCCC' }}>Attempt 1</div>
      <div style={{ textAlign: 'center', fontWeight: 'bold', color: '#CCCCCC' }}>Attempt 2</div>
      <div style={{ textAlign: 'center', fontWeight: 'bold', color: '#CCCCCC' }}>Attempt 3</div>
      <div style={{ textAlign: 'center', fontWeight: 'bold', color: '#CCCCCC' }}>Squat</div>
      {videos.slice(0, 3).map((video, index) => (
        <Video 
          video={video} 
          scale={scale} 
          key={index} 
          onEnded={handleVideoEnded}
          isPlaying={currentVideoIndex === index}
        />
      ))}
      <div style={{ textAlign: 'center', fontWeight: 'bold', color: '#CCCCCC' }}>Bench</div>
      {videos.slice(3, 6).map((video, index) => (
        <Video 
          video={video} 
          scale={scale} 
          key={index + 3} 
          onEnded={handleVideoEnded}
          isPlaying={currentVideoIndex === index + 3}
        />
      ))}
      <div style={{ textAlign: 'center', fontWeight: 'bold', color: '#CCCCCC' }}>Deadlift</div>
      {videos.slice(6, 9).map((video, index) => (
        <Video 
          video={video} 
          scale={scale} 
          key={index + 6} 
          onEnded={handleVideoEnded}
          isPlaying={currentVideoIndex === index + 6}
        />
      ))}
    </div>
  );
};

export default CompGrid20202;
