const MorningBodyweightData = [
  { id: '2', date: '16-Jul-23', weight: 72.1 }, //correct according to DateIDs.js
  { id: '3', date: '17-Jul-23', weight: 71.4 }, //correct according to DateIDs.js
  { id: '4', date: '18-Jul-23', weight: 71.8 }, //correct according to DateIDs.js
  { id: '5', date: '19-Jul-23', weight: 72.0 }, //correct according to DateIDs.js
  { id: '6', date: '20-Jul-23', weight: 72.2 }, //correct according to DateIDs.js
  { id: '9', date: '23-Jul-23', weight: 71.5 }, //correct according to DateIDs.js
  { id: '15', date: '29-Jul-23', weight: 72.1 }, //correct according to DateIDs.js
  { id: '16', date: '30-Jul-23', weight: 71.7 }, //correct according to DateIDs.js
  { id: '37', date: '20-Aug-23', weight: 72.6 },
  { id: '38', date: '21-Aug-23', weight: 72.1 },
  { id: '40', date: '23-Aug-23', weight: 72.0 },
  { id: '42', date: '25-Aug-23', weight: 72.3 },
  { id: '43', date: '26-Aug-23', weight: 72.3 },
  { id: '44', date: '27-Aug-23', weight: 72.6 },
  { id: '45', date: '28-Aug-23', weight: 72.6 },
  { id: '50', date: '02-Sep-23', weight: 72.6 },
  { id: '63', date: '15-Sep-23', weight: 71.5 },
  { id: '65', date: '17-Sep-23', weight: 72.7 },
  { id: '66', date: '18-Sep-23', weight: 73.0 },
  { id: '68', date: '20-Sep-23', weight: 72.4 },
  { id: '69', date: '21-Sep-23', weight: 72.5 },
  { id: '78', date: '30-Sep-23', weight: 73.0 },
  { id: '80', date: '02-Oct-23', weight: 72.2 },
  { id: '81', date: '03-Oct-23', weight: 73.8 },
  { id: '83', date: '05-Oct-23', weight: 74.1 },
  { id: '85', date: '07-Oct-23', weight: 73.8 },
  { id: '86', date: '08-Oct-23', weight: 72.9 },
  { id: '103', date: '25-Oct-23', weight: 73.9 },
  { id: '106', date: '28-Oct-23', weight: 74.2 },
  { id: '107', date: '29-Oct-23', weight: 73.6 },
  { id: '108', date: '30-Oct-23', weight: 73.5 },
  { id: '110', date: '01-Nov-23', weight: 73.5 },
  { id: '111', date: '02-Nov-23', weight: 72.6 },
  { id: '114', date: '05-Nov-23', weight: 73.6 },
  { id: '115', date: '06-Nov-23', weight: 72.6 },
  { id: '116', date: '07-Nov-23', weight: 71.8 },
  { id: '117', date: '08-Nov-23', weight: 73.5 },
  { id: '120', date: '11-Nov-23', weight: 73.6 },
  { id: '123', date: '14-Nov-23', weight: 72.8 },
  { id: '124', date: '15-Nov-23', weight: 71.3 },
  { id: '126', date: '17-Nov-23', weight: 72.6 },
  { id: '131', date: '22-Nov-23', weight: 72.0 },
  { id: '135', date: '26-Nov-23', weight: 72.6 },
  { id: '136', date: '27-Nov-23', weight: 72.0 },
  { id: '140', date: '01-Dec-23', weight: 73.1 },
  { id: '141', date: '02-Dec-23', weight: 73.1 },
  { id: '174', date: '04-Jan-24', weight: 71.9 },
  { id: '179', date: '09-Jan-24', weight: 71.7 },
  { id: '183', date: '13-Jan-24', weight: 72.0 },
  { id: '190', date: '20-Jan-24', weight: 71.8 },
  { id: '191', date: '21-Jan-24', weight: 73.1 },
  { id: '192', date: '22-Jan-24', weight: 72.1 },
  { id: '193', date: '23-Jan-24', weight: 72.5 },
  { id: '196', date: '26-Jan-24', weight: 71.4 },
  { id: '198', date: '28-Jan-24', weight: 71.6 },
  { id: '202', date: '01-Feb-24', weight: 72.6 },
  { id: '205', date: '04-Feb-24', weight: 71.6 },
  { id: '209', date: '08-Feb-24', weight: 73.0 }, //correct according to DateIDs.js
  { id: '212', date: '11-Feb-24', weight: 73.4 }, //correct according to DateIDs.js
  { id: '216', date: '15-Feb-24', weight: 74.0 }, //correct according to DateIDs.js
  { id: '217', date: '16-Feb-24', weight: 74.2 },
  { id: '218', date: '17-Feb-24', weight: 73.4 },
  { id: '220', date: '19-Feb-24', weight: 75.3 },
  { id: '221', date: '20-Feb-24', weight: 74.7 }, //correct according to DateIDs.js
  { id: '222', date: '21-Feb-24', weight: 74.7 },
  { id: '223', date: '22-Feb-24', weight: 75.8 },
  { id: '229', date: '28-Feb-24', weight: 74.8 },
  { id: '230', date: '29-Feb-24', weight: 74.3 }, //correct according to DateIDs.js
  { id: '239', date: '09-Mar-24', weight: 75.4 },
  { id: '240', date: '10-Mar-24', weight: 76.8 },
  { id: '242', date: '12-Mar-24', weight: 74.6 },
  { id: '244', date: '14-Mar-24', weight: 75.5 },
  { id: '245', date: '15-Mar-24', weight: 74.9 },
  { id: '246', date: '16-Mar-24', weight: 73.9 },
  { id: '247', date: '17-Mar-24', weight: 74.3 },
  { id: '249', date: '19-Mar-24', weight: 73.8 },
  { id: '255', date: '25-Mar-24', weight: 75.0 },
  { id: '256', date: '26-Mar-24', weight: 74.2 },
  { id: '257', date: '27-Mar-24', weight: 74.0 },
  { id: '258', date: '28-Mar-24', weight: 73.4 },
  { id: '259', date: '29-Mar-24', weight: 74.8 },
  { id: '260', date: '30-Mar-24', weight: 73.6 },
  { id: '270', date: '09-Apr-24', weight: 74.5 },
  { id: '271', date: '10-Apr-24', weight: 73.3 },
  { id: '272', date: '11-Apr-24', weight: 74.8 },
  { id: '273', date: '12-Apr-24', weight: 74.8 },
  { id: '274', date: '13-Apr-24', weight: 74.0 },
  { id: '275', date: '14-Apr-24', weight: 73.9 },
  { id: '277', date: '16-Apr-24', weight: 73.8 },
  { id: '281', date: '20-Apr-24', weight: 74.0 },
  { id: '283', date: '22-Apr-24', weight: 74.3 },
  { id: '284', date: '23-Apr-24', weight: 74.3 },
  { id: '285', date: '24-Apr-24', weight: 73.3 },
  { id: '286', date: '25-Apr-24', weight: 72.9 },
  { id: '290', date: '29-Apr-24', weight: 75.5 },
  { id: '296', date: '05-May-24', weight: 73.0 },
  { id: '297', date: '06-May-24', weight: 73.6 },
  { id: '298', date: '07-May-24', weight: 74.3 },
  { id: '299', date: '08-May-24', weight: 73.9 },
  { id: '300', date: '09-May-24', weight: 73.3 },
  { id: '303', date: '12-May-24', weight: 73.6 },
  { id: '304', date: '13-May-24', weight: 75.1 },
  { id: '306', date: '15-May-24', weight: 73.4 },
  { id: '349', date: '27-Jun-24', weight: 74.1 },
  { id: '350', date: '28-Jun-24', weight: 74.6 },
  { id: '351', date: '29-Jun-24', weight: 74.5 },
  { id: '352', date: '30-Jun-24', weight: 74.7 },
  { id: '353', date: '01-Jul-24', weight: 74.8 },
  { id: '354', date: '02-Jul-24', weight: 74.2 },
  { id: '357', date: '05-Jul-24', weight: 74.0 },
  { id: '358', date: '06-Jul-24', weight: 74.6 },
  { id: '360', date: '08-Jul-24', weight: 74.3 },
  { id: '366', date: '14-Jul-24', weight: 74.1 },
  { id: '367', date: '15-Jul-24', weight: 75.2 },
  { id: '382', date: '30-Jul-24', weight: 72.8 },
  { id: '384', date: '01-Aug-24', weight: 72.2 }, //correct according to DateIDs.js
  { id: '394', date: '11-Aug-24', weight: 74.9 }, //correct according to DateIDs.js
  { id: '395', date: '12-Aug-24', weight: 73.4 }, //correct according to DateIDs.js
  { id: '397', date: '14-Aug-24', weight: 73.6 }, //correct according to DateIDs.js
  { id: '398', date: '15-Aug-24', weight: 73.5 }, //correct according to DateIDs.js
  { id: '401', date: '18-Aug-24', weight: 73.8 }, //correct according to DateIDs.js
  { id: '414', date: '31-Aug-24', weight: 71.6 }, //correct according to DateIDs.js
  { id: '415', date: '01-Sep-24', weight: 72.1 }, //correct according to DateIDs.js
  { id: '416', date: '02-Sep-24', weight: 72.7 }, //correct according to DateIDs.js
  { id: '417', date: '03-Sep-24', weight: 71.7 }, //correct according to DateIDs.js
  { id: '419', date: '05-Sep-24', weight: 72.6 }, //correct according to DateIDs.js
  { id: '425', date: '11-Sep-24', weight: 72.7 }, //correct according to DateIDs.js
  { id: '427', date: '13-Sep-24', weight: 73.2 }, //correct according to DateIDs.js
  { id: '430', date: '16-Sep-24', weight: 72.8 },
];

export default MorningBodyweightData;