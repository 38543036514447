import React from 'react';
import BodyWeightChart from '../components/bodyweight/BodyWeightChart';

const Bodyweight = () => {
  return (
    <div> {/* Remove padding here */}
      <BodyWeightChart />
    </div>
  );
};

export default Bodyweight;