import React, { useState, useMemo, useEffect } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { useViewportScaling } from '../../hooks/useViewportScaling';
import morningData from './MorningBodyweightData';
import eveningData from './EveningBodyweightData';

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div style={{ 
        backgroundColor: '#000000', 
        padding: '10px', 
        border: '1px solid #CCCCCC',
        fontFamily: 'Arial, sans-serif',
        fontSize: '14px',
        color: '#CCCCCC'
      }}>
        <p>{`${payload[0].payload.date}`}</p>
        {payload.map((entry) => (
          <p key={entry.name} style={{ color: entry.color }}>
            {`${entry.name}: ${entry.value ? entry.value.toFixed(1) : 'N/A'} kg`}
          </p>
        ))}
      </div>
    );
  }
  return null;
};

const BodyWeightChart = () => {
  const { scale, scaleW, scaleH } = useViewportScaling();
  const [showPoints, setShowPoints] = useState(true);
  const [showLines, setShowLines] = useState(true);
  const [chartHeight, setChartHeight] = useState(0);

  const { combinedData, minId, maxId } = useMemo(() => {
    const allIds = new Set([...morningData, ...eveningData].map(d => parseInt(d.id)));
    const minId = Math.min(...allIds);
    const maxId = Math.max(...allIds);
    
    const morningMap = new Map(morningData.map(d => [d.id, { weight: d.weight, date: d.date }]));
    const eveningMap = new Map(eveningData.map(d => [d.id, { weight: d.weight, date: d.date }]));
    
    const combinedData = Array.from(allIds).map(id => {
      const morning = morningMap.get(id.toString());
      const evening = eveningMap.get(id.toString());
      return {
        id: id.toString(),
        date: morning?.date || evening?.date,
        morningWeight: morning?.weight || null,
        eveningWeight: evening?.weight || null
      };
    }).sort((a, b) => parseInt(a.id) - parseInt(b.id));

    return { combinedData, minId, maxId };
  }, []);

  useEffect(() => {
    setChartHeight(1000 * scale);
  }, [scale]);

  const lineColors = {
    morningWeight: '#FF8A65', // orange
    eveningWeight: '#E57373'  // red
  };

  const chartStyle = {
    position: 'absolute',
    left: `${1920 * scaleW}px`,
    top: `${1080 * scaleH}px`,
    transform: 'translate(-50%, -50%)',
    width: `${3000 * scale}px`,
    height: `${chartHeight}px`,
    overflow: 'hidden',
  };

  const checkboxContainerStyle = {
    position: 'absolute',
    right: `${20 * scale}px`,
    top: `${20 * scale}px`,
    zIndex: 1000,
    fontFamily: 'Arial, sans-serif',
    fontSize: `${24 * scale}px`,
    color: '#CCCCCC',
  };

  const checkboxStyle = {
    width: `${30 * scale}px`,
    height: `${30 * scale}px`,
    marginRight: `${10 * scale}px`,
  };

  const labelStyle = {
    display: 'flex',
    alignItems: 'center',
    fontFamily: 'Arial, sans-serif',
    fontSize: `${30 * scale}px`,
    marginBottom: `${30 * scale}px`,
  };

  const customXAxisTicks = [1, 32, 63, 93, 124, 154, 185, 216, 245, 276, 306, 337, 367, 398, 429];

  const formatXAxisTick = (tickItem) => {
    const tickToMonthMap = {
      1: 'Jul', 32: 'Aug', 63: 'Sep', 93: 'Oct', 124: 'Nov', 154: 'Dec',
      185: 'Jan', 216: 'Feb', 245: 'Mar', 276: 'Apr', 306: 'May', 337: 'Jun',
      367: 'Jul', 398: 'Aug', 429: 'Sep'
    };
    return tickToMonthMap[tickItem] || '';
  };

  return (
    <div style={chartStyle}>
      <div style={checkboxContainerStyle}>
        <label style={labelStyle}>
          <input
            type="checkbox"
            checked={showPoints}
            onChange={() => setShowPoints(!showPoints)}
            style={checkboxStyle}
          />
          <span>Points</span>
        </label>
        <label style={labelStyle}>
          <input
            type="checkbox"
            checked={showLines}
            onChange={() => setShowLines(!showLines)}
            style={checkboxStyle}
          />
          <span>Lines</span>
        </label>
      </div>
      <ResponsiveContainer width="100%" height="100%">
        <LineChart 
          data={combinedData} 
          margin={{ 
            top: 15 * scale, 
            right: 90 * scale, 
            left: 60 * scale, 
            bottom: 15 * scale 
          }}
        >
          <CartesianGrid strokeDasharray="3 3" stroke="#333333" horizontal={true} vertical={false} />
          <XAxis
            dataKey="id"
            type="number"
            domain={[minId, maxId]}
            ticks={customXAxisTicks}
            tickFormatter={formatXAxisTick}
            tick={{ fill: '#CCCCCC' }}
            axisLine={{ stroke: '#CCCCCC' }}
            tickLine={false}
            padding={{ left: 30, right: 30 }}
          />
          <YAxis
            domain={[70, 78]}
            ticks={[70, 71, 72, 73, 74, 75, 76, 77, 78]}
            tick={{ fill: '#CCCCCC' }}
            label={{ value: 'Weight (kg)', angle: -90, position: 'insideLeft', fill: '#CCCCCC', dx: -10, dy: 40 }}
          />
          <Tooltip content={<CustomTooltip />} />
          <Legend wrapperStyle={{ top: chartHeight - 20, color: '#CCCCCC' }} />
          {Object.entries(lineColors).map(([key, color]) => (
            <Line
              key={key}
              type="monotone"
              dataKey={key}
              stroke={color}
              strokeWidth={2}
              dot={showPoints ? { r: 3, strokeWidth: 2, fill: color } : false}
              activeDot={showPoints ? { r: 5, strokeWidth: 2, fill: color } : false}
              name={key === 'morningWeight' ? 'Morning Weight' : 'Evening Weight'}
              filter="url(#glow)"
              hide={false}
              strokeOpacity={showLines ? 1 : 0}
              connectNulls={true}
            />
          ))}
          <defs>
            <filter id="glow">
              <feGaussianBlur stdDeviation="2.5" result="coloredBlur" />
              <feMerge>
                <feMergeNode in="coloredBlur" />
                <feMergeNode in="SourceGraphic" />
              </feMerge>
            </filter>
          </defs>
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

export default BodyWeightChart;