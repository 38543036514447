import React from 'react';
import { useViewportScaling } from '../hooks/useViewportScaling';
import homeImage from '../media/home/home-image.jpg';

const RourkePalmer = () => {
  const { scale } = useViewportScaling();

  const containerStyle = {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 20, // High z-index, but below the Navbar
    pointerEvents: 'none', // Allow clicking through this layer
  };

  const imageStyle = {
    position: 'absolute',
    left: '50%',
    top: '60%',
    transform: 'translate(-50%, -50%)',
    width: `${1000 * scale}px`,
    height: 'auto',
    zIndex: 19, // Lower z-index than the text
  };

  const textStyle = {
    position: 'absolute',
    left: '50%',
    top: '30%',
    transform: 'translate(-50%, -50%)',
    textAlign: 'center',
    color: '#CCCCCC',
    fontSize: `${30 * scale}px`,
    fontFamily: 'Arial, sans-serif',
    lineHeight: `${60 * scale}px`,
    pointerEvents: 'auto', // Re-enable pointer events for the text
    zIndex: 21, // Higher z-index than the image
  };

  return (
    <div style={containerStyle}>
      <img src={homeImage} alt="Home" style={imageStyle} />
      <div style={textStyle}>
        <p>
          Hi! My name is Rourke Regan Palmer. This is a personal website that I made to bring together a collection of information about myself.
        </p>
        <p>
          I was born on 01-June-2003 in Kingston Hospital, London, UK.
        </p>
      </div>
    </div>
  );
};

export default RourkePalmer;