import React, { useEffect, useState, useRef, useCallback } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useViewportScaling } from '../../hooks/useViewportScaling';

const HealthNavbar = () => {
  const [treeOrigin, setTreeOrigin] = useState({ x: 0, y: 0 });
  const [isLoaded, setIsLoaded] = useState(false);
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
  const location = useLocation();
  const textRefs = useRef({});
  const navRef = useRef(null);
  const { scale, scaleW, scaleH } = useViewportScaling();

  const healthCategories = [
    { name: 'Blood Tests', x: 400, y: 300, path: '/health/blood-tests' },
    { name: 'Bodyweight', x: 800, y: 300, path: '/health/bodyweight' },
    { name: 'Metabolic', x: 1200, y: 300, path: '/health/metabolic' },
    { name: 'MRI', x: 1500, y: 300, path: '/health/mri' },
    { name: 'DEXA', x: 1800, y: 300, path: '/health/dexa' },
    { name: 'Cognitive', x: 2100, y: 300, path: '/health/cognitive' }
  ];

  const updateTreeOrigin = useCallback(() => {
    setTreeOrigin({
      x: 120 * scale,
      y: 180 * scale
    });
  }, [scale]);

  const updateDimensions = useCallback(() => {
    if (navRef.current) {
      setDimensions({
        width: navRef.current.offsetWidth,
        height: navRef.current.offsetHeight
      });
    }
  }, []);

  useEffect(() => {
    const handleResize = () => {
      updateTreeOrigin();
      updateDimensions();
    };

    handleResize(); // Call once to set initial sizes

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [updateTreeOrigin, updateDimensions]);

  useEffect(() => {
    // Delay setting isLoaded to true to ensure all calculations are done
    const timer = setTimeout(() => {
      setIsLoaded(true);
    }, 100);

    return () => clearTimeout(timer);
  }, [dimensions]);

  const createPath = (endX, endY) => {
    const midY = (treeOrigin.y + endY * scaleH) / 2;
    return `M${treeOrigin.x},${treeOrigin.y} C${treeOrigin.x},${midY} ${endX * scaleW - 500 * scaleW},${midY} ${endX * scaleW},${endY * scaleH - 40 * scaleH}`;
  };

  const navStyle = {
    width: '100%',
    height: `${350 * scaleH}px`,
    position: 'relative',
    backgroundColor: 'transparent',
    paddingTop: `${80 * scaleH}px`,
    paddingBottom: `${100 * scaleH}px`,
    zIndex: 20,
    transition: 'opacity 300ms',
    opacity: isLoaded ? 1 : 0,
    overflow: 'hidden'
  };

  const linkStyle = {
    color: '#CCCCCC',
    fontSize: `${35 * scale}px`,
    padding: `${10 * scale}px`,
    borderRadius: `${20 * scale}px`,
    textDecoration: 'none',
    transition: 'all 300ms',
    display: 'inline-block',
    whiteSpace: 'nowrap',
    position: 'absolute',
  };

  const activeLinkStyle = {
    ...linkStyle,
    backgroundColor: '#333',
  };

  return (
    <nav ref={navRef} style={navStyle}>
      {healthCategories.map((category, index) => (
        <React.Fragment key={index}>
          <div
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              zIndex: 10,
              pointerEvents: 'none'
            }}
          >
            <svg width="100%" height="100%">
              <path
                d={createPath(category.x, category.y)}
                fill="none"
                stroke="#CCCCCC"
                strokeWidth={`${2.5 * scale}px`}
                style={{
                  transition: 'stroke-dashoffset 1s ease-in-out',
                  strokeDasharray: '1000',
                  strokeDashoffset: isLoaded ? 0 : 1000
                }}
              />
            </svg>
          </div>
          <Link 
            to={category.path} 
            style={{
              ...location.pathname === category.path ? activeLinkStyle : linkStyle,
              left: `${category.x * scaleW}px`,
              top: `${category.y * scaleH}px`,
              transform: 'translate(-50%, -50%)'
            }}
            ref={el => {
              textRefs.current[category.path] = el;
            }}
          >
            {category.name}
          </Link>
        </React.Fragment>
      ))}
    </nav>
  );
};

export default HealthNavbar;