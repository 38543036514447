import React from 'react';
import { useViewportScaling } from '../../hooks/useViewportScaling';

const SourceCognitive = ({ isVisible }) => {
  const { scale, scaleH } = useViewportScaling();

  const containerStyle = {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    zIndex: 20,
    pointerEvents: 'none',
    opacity: isVisible ? 1 : 0,
    transition: 'opacity 0.3s ease-in-out',
  };

  const textStyle = {
    position: 'absolute',
    left: '50%',
    top: `${200 * scaleH}px`,
    transform: 'translate(-100%, -50%)',
    textAlign: 'left',
    color: '#CCCCCC',
    fontSize: `${30 * scale}px`,
    fontFamily: 'Arial, sans-serif',
    lineHeight: `${60 * scale}px`,
    pointerEvents: 'auto',
  };

  return (
    <div style={containerStyle}>
      <div style={textStyle}>
        <p>
          Schaie KW, Willis SL, Caskie GI. The Seattle longitudinal study: relationship between personality and cognition. Neuropsychol Dev Cogn B Aging Neuropsychol Cogn. 2004 Jun;11(2-3):304-24. doi: 10.1080/13825580490511134. PMID: 16755303; PMCID: PMC1474018.
        </p>
      </div>
    </div>
  );
};

export default SourceCognitive;