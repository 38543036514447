import React, { useState, useEffect } from 'react';
import { useViewportScaling } from '../../hooks/useViewportScaling';
import CompGrid2019 from './2019CompGrid';
import CompGrid20202 from './2020CompGrid-2';
import CompGrid20201 from './2020CompGrid-1';

const PowerliftingPage = () => {
  const { scaleW, scaleH, scale } = useViewportScaling();
  const [selectedDot, setSelectedDot] = useState(null);
  const [hoveredDot, setHoveredDot] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);

  const lineStyle = {
    position: 'absolute',
    left: `${2400 * scaleW}px`,
    width: `${2000 * scaleW}px`,
    height: `${5 * scale}px`,
    backgroundColor: '#CCCCCC',
    transform: 'translate(-50%, -50%)',
    opacity: isLoaded ? 1 : 0,
    transition: 'opacity 0.5s ease-in-out',
    overflow: 'hidden',
    zIndex: 100,
  };

  const dotStyle = {
    position: 'absolute',
    width: `${30 * scale}px`,
    height: `${30 * scale}px`,
    borderRadius: '50%',
    transform: 'translate(-50%, -50%)',
    cursor: 'pointer',
    opacity: isLoaded ? 1 : 0,
    transition: 'opacity 0.5s ease-in-out',
    overflow: 'hidden',
    zIndex: 101,
  };

  const labelStyle = {
    position: 'absolute',
    fontFamily: 'Arial, sans-serif',
    fontSize: `${25 * scale}px`,
    color: '#CCCCCC',
    paddingTop: `${15 * scale}px`,
    transform: 'translateX(-50%)',
    opacity: isLoaded ? 1 : 0,
    overflow: 'hidden',
    transition: 'opacity 0.5s ease-in-out',
  };

  const yearLabelStyle = {
    position: 'absolute',
    fontFamily: 'Arial, sans-serif',
    fontSize: `${40 * scale}px`,
    color: '#CCCCCC',
    transform: 'translate(-50%, -50%)',
    left: `${3500 * scaleW}px`,
    opacity: isLoaded ? 1 : 0,
    overflow: 'hidden',
    transition: 'opacity 0.5s ease-in-out',
  };

  const modalStyle = {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: '#000000',
    padding: `${20 * scale}px`,
    border: `${3 * scale}px solid #CCCCCC`,
    fontFamily: 'Arial, sans-serif',
    color: '#CCCCCC',
    overflow: 'hidden',
    zIndex: 1000,
  };

  const modalTitleStyle = {
    fontSize: `${40 * scale}px`,
    marginBottom: `${10 * scale}px`,
  };

  const modalTextStyle = {
    fontSize: `${30 * scale}px`,
    marginBottom: `${10 * scale}px`,
  };

  const closeButtonStyle = {
    position: 'absolute',
    top: `${10 * scale}px`,
    right: `${30 * scale}px`,
    cursor: 'pointer',
    fontSize: `${40 * scale}px`,
    color: '#CCCCCC',
  };

  const tableStyle = {
    position: 'absolute',
    left: `${600 * scaleW}px`,
    top: `${1330 * scaleH}px`,
    width: `${1000 * scale}px`,
    borderCollapse: 'separate',
    borderSpacing: 0,
    transform: 'translate(-50%, -50%)',
    opacity: isLoaded ? 1 : 0,
    transition: 'opacity 0.5s ease-in-out',
    zIndex: 50,
    overflow: 'hidden',
    color: '#CCCCCC',
  };

  const cellStyle = {
    padding: `${3 * scale}px ${10 * scale}px`,
    textAlign: 'left',
    fontSize: `${30 * scale}px`,
  };

  const subheadingStyle = {
    fontWeight: 'bold',
    paddingTop: `${25 * scale}px`,
    paddingBottom: `${20 * scale}px`,
    paddingLeft: `${10 * scale}px`,
    textAlign: 'left',
    fontSize: `${35 * scale}px`
  };

  const linkStyle = {
    cursor: 'pointer',
    textDecoration: 'none',
    color: '#CCCCCC',
  };

  const noteStyle = {
    position: 'absolute',
    left: `${1400 * scaleW}px`,
    top: `${700 * scaleH}px`,
    fontSize: `${40 * scale}px`,
    fontStyle: 'italic',
    color: '#CCCCCC',
    opacity: isLoaded ? 1 : 0,
    transition: 'opacity 0.5s ease-in-out',
  };

  const dots = [
    { id: '27-Mar-2018', top: 1550 * scaleH, left: 1400 * scaleW + 0.236 * 2000 * scaleW, label: '' },
    { id: '23-Feb-2019', top: 1330 * scaleH, left: 1400 * scaleW + 0.148 * 2000 * scaleW, label: '' },
    { id: '22-Mar-2019', top: 1330 * scaleH, left: 1400 * scaleW + 0.222 * 2000 * scaleW, label: '' },
    { id: '25-Mar-2019', top: 1330 * scaleH, left: 1400 * scaleW + 0.230 * 2000 * scaleW, label: '' },
    { id: '28-Mar-2019', top: 1330 * scaleH, left: 1400 * scaleW + 0.238 * 2000 * scaleW, label: '' },
    { id: '27-Jul-2019', top: 1330 * scaleH, left: 1400 * scaleW + 0.570 * 2000 * scaleW, label: '' },
    { id: '01-Dec-2019', top: 1330 * scaleH, left: 1400 * scaleW + 0.918 * 2000 * scaleW, label: '' },
    { id: '04-Dec-2019', top: 1330 * scaleH, left: 1400 * scaleW + 0.926 * 2000 * scaleW, label: '' },
    { id: '10-Dec-2019', top: 1330 * scaleH, left: 1400 * scaleW + 0.942 * 2000 * scaleW, label: '' },
    { id: '08-Feb-2020', top: 1080 * scaleH, left: 1400 * scaleW + 0.107 * 2000 * scaleW, label: '' },
    { id: '28-Jun-2020', top: 1080 * scaleH, left: 1400 * scaleW + 0.490 * 2000 * scaleW, label: '' },
    { id: '07-Aug-2020', top: 1080 * scaleH, left: 1400 * scaleW + 0.600 * 2000 * scaleW, label: '' },
    { id: '15-Aug-2020', top: 1080 * scaleH, left: 1400 * scaleW + 0.622 * 2000 * scaleW, label: '' },
    { id: '21-Sep-2020', top: 1080 * scaleH, left: 1400 * scaleW + 0.723 * 2000 * scaleW, label: '' },
    { id: '27-Sep-2020', top: 1080 * scaleH, left: 1400 * scaleW + 0.740 * 2000 * scaleW, label: '' },
    { id: '06-Nov-2020', top: 1080 * scaleH, left: 1400 * scaleW + 0.849 * 2000 * scaleW, label: '' },
    { id: '10-Oct-2020', top: 1080 * scaleH, left: 1400 * scaleW + 0.775 * 2000 * scaleW, label: '' },
  ];

  const tableData = [
    { subheading: "2020", data: [
      { lift: "Deadlift (sumo)", 	weight: "240kg", 	reps: "x1", 	where: "Training", 	    age: "17.4", 	id: "06-Nov-2020" },
      { lift: "Deadlift (conv)", 	weight: "235kg", 	reps: "x1", 	where: "Competition",   age: "17.4", 	id: "10-Oct-2020" },
      { lift: "Bench", 	            weight: "135kg", 	reps: "x1", 	where: "Competition",  	age: "17.4", 	id: "10-Oct-2020" },
      { lift: "Squat (wrapped)",    weight: "235kg", 	reps: "x1", 	where: "Competition",  	age: "17.4", 	id: "10-Oct-2020" },
      { lift: "Bench", 			    weight: "135kg", 	reps: "x1", 	where: "Training",  	age: "17.3", 	id: "27-Sep-2020" },
      { lift: "Squat (wrapped)", 	weight: "240kg", 	reps: "x1", 	where: "Training", 	    age: "17.3", 	id: "21-Sep-2020" },
      { lift: "Squat (sleeves)", 	weight: "220kg", 	reps: "x1", 	where: "Training", 	    age: "17.2", 	id: "15-Aug-2020" },
      { lift: "Bench", 			    weight: "115kg", 	reps: "x5", 	where: "Training", 	    age: "17.2", 	id: "07-Aug-2020" },
      { lift: "Deadlift (conv)", 	weight: "215kg", 	reps: "x2", 	where: "Training", 	    age: "17.1", 	id: "28-Jun-2020" },
      { lift: "Deadlift (conv)", 	weight: "220kg", 	reps: "x1", 	where: "Competition", 	age: "16.7", 	id: "08-Feb-2020" },
      { lift: "Bench", 			    weight: "125kg", 	reps: "x1", 	where: "Competition", 	age: "16.7", 	id: "08-Feb-2020" },
      { lift: "Squat (wrapped)", 	weight: "215kg", 	reps: "x1", 	where: "Competition", 	age: "16.7", 	id: "08-Feb-2020" },
    ]},
    { subheading: "2019", data: [
      { lift: "Bench", 			    weight: "115kg", 	reps: "x2", 	where: "Training",  	age: "16.5", 	id: "10-Dec-2019" },
      { lift: "Deadlift (conv)", 	weight: "200kg", 	reps: "x2", 	where: "Training", 	    age: "16.5", 	id: "04-Dec-2019" },
      { lift: "Squat (wrapped)", 	weight: "190kg", 	reps: "x1", 	where: "Training", 	    age: "16.5", 	id: "01-Dec-2019" },
      { lift: "Deadlift (conv)", 	weight: "170kg", 	reps: "x5", 	where: "Training", 	    age: "16.2", 	id: "27-Jul-2019" },
      { lift: "Squat (sleeves)", 	weight: "~180kg", 	reps: "x1", 	where: "Training", 	    age: "15.8", 	id: "28-Mar-2019" },
      { lift: "Bench", 			    weight: "90kg", 	reps: "x1", 	where: "Training",  	age: "15.8", 	id: "25-Mar-2019" },
      { lift: "Deadlift (conv)", 	weight: "190kg", 	reps: "x1", 	where: "Training",  	age: "15.8", 	id: "22-Mar-2019" },
      { lift: "Deadlift (conv)", 	weight: "165kg", 	reps: "x1", 	where: "Competition", 	age: "15.7", 	id: "23-Feb-2019" },
      { lift: "Bench", 			    weight: "100kg", 	reps: "x1", 	where: "Competition", 	age: "15.7", 	id: "23-Feb-2019" },
      { lift: "Squat (sleeves)", 	weight: "165kg", 	reps: "x1", 	where: "Competition", 	age: "15.7", 	id: "23-Feb-2019" },
    ]},
    { subheading: "2018", data: [
      { lift: "Deadlift (conv)", 	weight: "90kg", 	reps: "5", 	where: "Training",  	age: "14.8", 	id: "27-Mar-2018" },
    ]},
  ];

  const handleDotClick = (dotId) => {
    setSelectedDot(dotId);
  };

  const handleCloseModal = () => {
    setSelectedDot(null);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (selectedDot && !event.target.closest('.modal')) {
        handleCloseModal();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [selectedDot]);

  useEffect(() => {
    // Simulate loading delay
    const timer = setTimeout(() => {
      setIsLoaded(true);
    }, 100);

    return () => clearTimeout(timer);
  }, []);

  const renderModalContent = () => {
    switch (selectedDot) {
      case '27-Mar-2018':
        return (
          <>
            <h2 style={modalTitleStyle}>27-Mar-2018</h2>
            <p style={modalTextStyle}>Deadlift: 90kg x5 <br/> Age: 14.8 years old</p>
            <video width={`${800 * scale}px`} height="auto" controls autoPlay muted playsInline>
              <source src={require('../../media/gym/powerlifting/27-Mar-18.mp4')} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </>
        );
      case '23-Feb-2019':
        return (
          <>
            <h2 style={modalTitleStyle}>23-Feb-2019</h2>
            <p style={modalTextStyle}>COMPETITION <br/> Age: 15.7 years old</p>
            <CompGrid2019 />
          </>
        );
      case '22-Mar-2019':
        return (
          <>
            <h2 style={modalTitleStyle}>22-Mar-2019</h2>
            <p style={modalTextStyle}>Deadlift: 190kg x1 <br/> Age: 15.8 years old</p>
            <video width={`${800 * scale}px`} height="auto" controls autoPlay muted playsInline>
              <source src={require('../../media/gym/powerlifting/22-Mar-19.mp4')} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </>
        );
      case '25-Mar-2019':
        return (
          <>
            <h2 style={modalTitleStyle}>25-Mar-2019</h2>
            <p style={modalTextStyle}>Bench: 90kg x1 <br/> Age: 15.8 years old</p>
            <video width={`${800 * scale}px`} height="auto" controls autoPlay muted playsInline>
              <source src={require('../../media/gym/powerlifting/25-Mar-19.mp4')} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </>
        );
      case '28-Mar-2019':
        return (
          <>
            <h2 style={modalTitleStyle}>28-Mar-2019</h2>
            <p style={modalTextStyle}>Squat: ~180kg x1 <br/> Age: 15.8 years old</p>
            <video width={`${800 * scale}px`} height="auto" controls autoPlay muted playsInline>
              <source src={require('../../media/gym/powerlifting/28-Mar-19.mp4')} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </>
        );
      case '27-Jul-2019':
        return (
          <>
            <h2 style={modalTitleStyle}>27-Jul-2019</h2>
            <p style={modalTextStyle}>Deadlift: 170kg x5 <br/> Age: 16.2 years old</p>
            <video width={`${2000 * scale}px`} height="auto" controls autoPlay muted playsInline>
              <source src={require('../../media/gym/powerlifting/27-Jul-19.mp4')} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </>
        );
      case '01-Dec-2019':
        return (
          <>
            <h2 style={modalTitleStyle}>01-Dec-2019</h2>
            <p style={modalTextStyle}>Squat: 190kg x1 <br/> Age: 16.5 years old</p>
            <video width={`${2000 * scale}px`} height="auto" controls autoPlay muted playsInline>
              <source src={require('../../media/gym/powerlifting/1-Dec-19.mp4')} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </>
        );
      case '04-Dec-2019':
        return (
          <>
            <h2 style={modalTitleStyle}>04-Dec-2019</h2>
            <p style={modalTextStyle}>Deadlift: 200kg x2 <br/> Age: 16.5 years old</p>
            <video width={`${2000 * scale}px`} height="auto" controls autoPlay muted playsInline>
              <source src={require('../../media/gym/powerlifting/4-Dec-19.mp4')} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </>
        );
      case '10-Dec-2019':
        return (
          <>
            <h2 style={modalTitleStyle}>10-Dec-2019</h2>
            <p style={modalTextStyle}>Bench: 115kg x2 <br/> Age: 16.5 years old</p>
            <video width={`${2000 * scale}px`} height="auto" controls autoPlay muted playsInline>
              <source src={require('../../media/gym/powerlifting/10-Dec-19.mp4')} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </>
        );
      case '08-Feb-2020':
        return (
          <>
            <h2 style={modalTitleStyle}>08-Feb-2020</h2>
            <p style={modalTextStyle}>COMPETITION <br/> Age: 16.7 years old</p>
            <CompGrid20201 />
          </>
        );
      case '28-Jun-2020':
        return (
          <>
            <h2 style={modalTitleStyle}>28-Jun-2020</h2>
            <p style={modalTextStyle}>Deadlift: 215kg x2 <br/> Age: 17.1 years old</p>
            <video width={`${2000 * scale}px`} height="auto" controls autoPlay muted playsInline>
              <source src={require('../../media/gym/powerlifting/28-Jun-20.mp4')} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </>
        );
      case '07-Aug-2020':
        return (
          <>
            <h2 style={modalTitleStyle}>07-Aug-2020</h2>
            <p style={modalTextStyle}>Bench: 115kg x5 <br/> Age: 17.2 years old</p>
            <video width={`${2000 * scale}px`} height="auto" controls autoPlay muted playsInline>
              <source src={require('../../media/gym/powerlifting/7-Aug-20.mp4')} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </>
        );
      case '15-Aug-2020':
        return (
          <>
            <h2 style={modalTitleStyle}>15-Aug-2020</h2>
            <p style={modalTextStyle}>Squat: 220kg x1 <br/> Age: 17.2 years old</p>
            <video width={`${2000 * scale}px`} height="auto" controls autoPlay muted playsInline>
              <source src={require('../../media/gym/powerlifting/15-Aug-20.mp4')} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </>
        );
      case '21-Sep-2020':
        return (
          <>
            <h2 style={modalTitleStyle}>21-Sep-2020</h2>
            <p style={modalTextStyle}>Squat: 240kg x1 <br/> Age: 17.3 years old</p>
            <video width={`${2000 * scale}px`} height="auto" controls autoPlay muted playsInline>
              <source src={require('../../media/gym/powerlifting/21-Sep-20.mp4')} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </>
        );
      case '27-Sep-2020':
        return (
          <>
            <h2 style={modalTitleStyle}>27-Sep-2020 <br/> Age: 17.3 years old</h2>
            <p style={modalTextStyle}>Bench: 135kg x1</p>
            <video width={`${2000 * scale}px`} height="auto" controls autoPlay muted playsInline>
              <source src={require('../../media/gym/powerlifting/27-Sep-20.mp4')} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </>
        );
      case '06-Nov-2020':
        return (
          <>
            <h2 style={modalTitleStyle}>06-Nov-2020 <br/> Age: 17.4 years old</h2>
            <p style={modalTextStyle}>Deadlift: 240kg x1</p>
            <video width={`${2000 * scale}px`} height="auto" controls autoPlay muted playsInline>
              <source src={require('../../media/gym/powerlifting/6-Nov-20.mp4')} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </>
        );
      case '10-Oct-2020':
        return (
          <>
            <h2 style={modalTitleStyle}>10-Oct-2020</h2>
            <p style={modalTextStyle}>COMPETITION <br/> Age: 17.4 years old</p>
            <CompGrid20202 />
          </>
        );
      default:
        return null;
    }
  };

  return (
    <div>
      <div style={noteStyle}>
        Click on an item from the table, or click on a dot to see the corresponding video(s)
      </div>
      {dots.map((dot) => (
        <React.Fragment key={dot.id}>
          <div style={{ ...lineStyle, top: dot.top }} />
          <div
            style={{
              ...dotStyle,
              top: dot.top,
              left: dot.left,
              backgroundColor: (hoveredDot === dot.id || selectedDot === dot.id) ? 'red' : '#4FC3F7',
            }}
            onClick={() => handleDotClick(dot.id)}
            onMouseEnter={() => setHoveredDot(dot.id)}
            onMouseLeave={() => setHoveredDot(null)}
          />
          <div style={{ ...labelStyle, top: dot.top + 30 * scale, left: dot.left }}>
            {dot.label}
          </div>
        </React.Fragment>
      ))}
      <div style={{ ...yearLabelStyle, top: 1550 * scaleH }}>2018</div>
      <div style={{ ...yearLabelStyle, top: 1330 * scaleH }}>2019</div>
      <div style={{ ...yearLabelStyle, top: 1080 * scaleH }}>2020</div>
      {selectedDot && (
        <div className="modal" style={modalStyle}>
          <span style={closeButtonStyle} onClick={handleCloseModal}>
            &times;
          </span>
          {renderModalContent()}
        </div>
      )}
      <div style={tableStyle}>
        <table style={{ width: '100%' }}>
          <tbody>
            {tableData.map((section, index) => (
              <React.Fragment key={index}>
                <tr>
                  <td colSpan="6" style={subheadingStyle}>
                    {section.subheading}
                  </td>
                </tr>
                {section.data.map((row, rowIndex) => (
                  <tr
                    key={rowIndex}
                    style={{
                      ...linkStyle,
                      cursor: 'pointer',
                      textDecoration: (hoveredDot === row.id || selectedDot === row.id) ? 'underline' : 'none',
                      color: (hoveredDot === row.id || selectedDot === row.id) ? 'red' : '#CCCCCC',
                    }}
                    onClick={() => handleDotClick(row.id)}
                    onMouseEnter={() => setHoveredDot(row.id)}
                    onMouseLeave={() => setHoveredDot(null)}
                  >
                    <td style={cellStyle}>{row.lift}</td>
                    <td style={cellStyle}>{row.weight}</td>
                    <td style={cellStyle}>{row.reps}</td>
                    <td style={cellStyle}>{row.where}</td>
                    <td style={cellStyle}>{row.age}</td>
                    <td style={cellStyle}>{row.id}</td>
                  </tr>
                ))}
              </React.Fragment>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default PowerliftingPage;