import React from 'react';

const PhysicalActivities = () => {
  const tableData = [
    { code: '13050', met: 2, activity: 'showering, toweling off (standing)' },
    { code: '12120', met: 16, activity: 'Running, 10mph (6 min/mile)' },
    { code: '12030', met: 8, activity: 'Running, 5mph (12 min/mile)' },
    { code: '7030', met: 0.9, activity: 'Sleeping' },
    { code: '12080', met: 12.5, activity: 'Running, 7.5 mph (8 min/mile)' },
    { code: '17020', met: 3, activity: 'Walking downstairs' },
    { code: '17160', met: 2.5, activity: 'Walking 2.0 mph, level, slow pace, firm surface' },
    { code: '17190', met: 3.5, activity: 'Walking 3.0 mph, level, moderate pace, firm surface' },
    { code: '17220', met: 4, activity: 'Walking 4.0 mph, level, firm suface, very brisk pace' },
    { code: '17026', met: 5, activity: 'Walking 3.0 mph carying 1-15lb load upstairs' },
  ];

  const tableStyle = {
    width: '100%',
    borderCollapse: 'collapse',
    color: '#CCCCCC',
  };

  const headerStyle = {
    textAlign: 'center',
    paddingBottom: '5px',
    paddingTop: '5px',
    borderBottom: '1px solid rgba(204, 204, 204, 0.5)',
    fontSize: '1.1em',
  };

  const cellStyle = {
    padding: '3px 10px',
    borderBottom: '1px solid rgba(204, 204, 204, 0.5)',
    textAlign: 'center',
  };

  const titleStyle = {
    fontSize: '1.5em',
    marginBottom: '15px',
    fontWeight: 'bold',
    color: '#CCCCCC',
  };

  const citationStyle = {
    fontSize: '0.9em',
    marginBottom: '15px',
    lineHeight: '1.4',
    fontStyle: 'italic',
    color: '#CCCCCC',
  };

  return (
    <div style={{ color: '#CCCCCC', padding: '20px', backgroundColor: '#111111' }}>
        <p style={citationStyle}>
        Authors: AINSWORTH, BARBARA E.; HASKELL, WILLIAM L.; LEON, ARTHUR S.; JACOBS, DAVID R. JR.; MONTOYE, HENRY J.; SALLIS, JAMES F.; PAFFENBARGER, RALPH S. JR.. <br />
        Title: "Compendium of Physical Activities: classification of energy costs of human physical activities."<br />
        Find At: Medicine & Science in Sports & Exercise 25(1):p 71-80, January 1993. 
      </p>
      <h2 style={titleStyle}>Physical Activities and Their MET Levels</h2>
      <table style={tableStyle}>
        <thead>
          <tr>
            <th style={headerStyle}>Code</th>
            <th style={headerStyle}>MET Level</th>
            <th style={headerStyle}>Type of Activity</th>
          </tr>
        </thead>
        <tbody>
          {tableData.map((row, index) => (
            <tr key={index}>
              <td style={cellStyle}>{row.code}</td>
              <td style={cellStyle}>{row.met}</td>
              <td style={cellStyle}>{row.activity}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default PhysicalActivities;