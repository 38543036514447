import React from 'react';
import BenchPressChart from '../components/bench-press/BenchPressChart';

const BenchPress = () => {
  return (
    <div> {/* Remove padding here */}
      <BenchPressChart />
    </div>
  );
};

export default BenchPress;