const PBsData = [
    // 1 rep
    { id: '60', date: '12-Sep-23', weight: 125, reps: 1, video: 'Yes' },
    { id: '69', date: '21-Sep-23', weight: 135, reps: 1, video: 'Yes' },
    { id: '119', date: '10-Nov-23', weight: 138, reps: 1, video: 'Yes' },
    { id: '385', date: '02-Aug-24', weight: 140, reps: 1, video: 'No' },
    { id: '402', date: '19-Aug-24', weight: 145, reps: 1, video: 'No' },
    { id: '430', date: '16-Sep-24', weight: 150, reps: 1, video: 'Yes' },

    // 2 reps
    { id: '49', date: '1-Sep-23', weight: 120, reps: 2, video: 'Yes' },
    { id: '64', date: '16-Sep-23', weight: 125, reps: 2, video: 'Yes' },
    { id: '353', date: '01-Jul-24', weight: 130, reps: 2, video: 'No' },
    { id: '367', date: '15-Jul-24', weight: 130, reps: 2, video: 'No' },
    { id: '385', date: '02-Aug-24', weight: 135, reps: 2, video: 'No' },
    { id: '402', date: '19-Aug-24', weight: 140, reps: 2, video: 'No' },
    { id: '406', date: '23-Aug-24', weight: 140, reps: 2, video: 'No' },
    { id: '409', date: '26-Aug-24', weight: 140, reps: 2, video: 'No' },
    { id: '427', date: '13-Sep-24', weight: 140, reps: 2, video: 'Yes' },

    // 3 reps
    { id: '54', date: '6-Sep-23', weight: 120, reps: 3, video: 'Yes' },
    { id: '64', date: '16-Sep-23', weight: 120, reps: 3, video: 'Yes' },
    { id: '73', date: '25-Sep-23', weight: 125, reps: 3, video: 'No' },
    { id: '78', date: '30-Sep-23', weight: 125, reps: 3, video: 'Yes' },
    { id: '82', date: '4-Oct-23', weight: 125, reps: 3, video: 'No' },
    { id: '116', date: '7-Nov-23', weight: 125, reps: 3, video: 'Yes' },
    { id: '126', date: '17-Nov-23', weight: 125, reps: 3, video: 'Yes' },
    { id: '350', date: '28-Jun-24', weight: 130, reps: 3, video: 'No' },
    { id: '353', date: '01-Jul-24', weight: 130, reps: 3, video: 'No' },
    { id: '357', date: '05-Jul-24', weight: 130, reps: 3, video: 'No' },
    { id: '364', date: '12-Jul-24', weight: 130, reps: 3, video: 'No' },
    { id: '367', date: '15-Jul-24', weight: 130, reps: 3, video: 'No' },
    { id: '377', date: '25-Jul-24', weight: 135, reps: 3, video: 'No' },
    { id: '381', date: '29-Jul-24', weight: 135, reps: 3, video: 'No' },
    { id: '385', date: '02-Aug-24', weight: 135, reps: 3, video: 'No' },
    { id: '394', date: '11-Aug-24', weight: 140, reps: 3, video: 'No' },

    // 4 reps
    { id: '42', date: '25-Aug-23', weight: 110, reps: 4, video: 'No' },
    { id: '49', date: '1-Sep-23', weight: 110, reps: 4, video: 'No' },
    { id: '78', date: '30-Sep-23', weight: 120, reps: 4, video: 'No' },
    { id: '111', date: '2-Nov-23', weight: 120, reps: 4, video: 'Yes' },
    { id: '187', date: '17-Jan-24', weight: 120, reps: 4, video: 'No' },
    { id: '193', date: '23-Jan-24', weight: 120, reps: 4, video: 'No' },
    { id: '242', date: '11-Mar-24', weight: 125, reps: 4, video: 'No' },
    { id: '294', date: '03-May-24', weight: 125, reps: 4, video: 'No' },
    { id: '297', date: '06-May-24', weight: 125, reps: 4, video: 'No' },
    { id: '357', date: '05-Jul-24', weight: 130, reps: 4, video: 'No' },
    { id: '364', date: '12-Jul-24', weight: 130, reps: 4, video: 'No' },
    { id: '371', date: '19-Jul-24', weight: 130, reps: 4, video: 'No' },
    { id: '377', date: '25-Jul-24', weight: 130, reps: 4, video: 'No' },
    { id: '381', date: '29-Jul-24', weight: 130, reps: 4, video: 'No' },
    { id: '385', date: '02-Aug-24', weight: 130, reps: 4, video: 'No' },
    { id: '394', date: '11-Aug-24', weight: 130, reps: 4, video: 'No' },
    { id: '421', date: '7-Sep-24', weight: 130, reps: 4, video: 'Yes' },

    // 5 reps
    { id: '1', date: '15-Jul-23', weight: 90, reps: 5, video: 'No' },
    { id: '5', date: '19-Jul-23', weight: 100, reps: 5, video: 'No' },
    { id: '13', date: '27-Jul-23', weight: 100, reps: 5, video: 'No' },
    { id: '22', date: '5-Aug-23', weight: 100, reps: 5, video: 'No' },
    { id: '33', date: '16-Aug-23', weight: 100, reps: 5, video: 'No' },
    { id: '37', date: '20-Aug-23', weight: 105, reps: 5, video: 'No' },
    { id: '54', date: '6-Sep-23', weight: 110, reps: 5, video: 'No' },
    { id: '60', date: '12-Sep-23', weight: 110, reps: 5, video: 'No' },
    { id: '64', date: '16-Sep-23', weight: 110, reps: 5, video: 'No' },
    { id: '69', date: '21-Sep-23', weight: 110, reps: 5, video: 'No' },
    { id: '73', date: '25-Sep-23', weight: 111, reps: 5, video: 'No' },
    { id: '78', date: '30-Sep-23', weight: 111, reps: 5, video: 'No' },
    { id: '102', date: '24-Oct-23', weight: 116, reps: 5, video: 'Yes' },
    { id: '106', date: '28-Oct-23', weight: 116, reps: 5, video: 'No' },
    { id: '119', date: '10-Nov-23', weight: 116, reps: 5, video: 'No' },
    { id: '175', date: '5-Jan-24', weight: 116, reps: 5, video: 'No' },
    { id: '179', date: '9-Jan-24', weight: 116, reps: 5, video: 'No' },
    { id: '209', date: '8-Feb-24', weight: 116, reps: 5, video: 'Yes' },
    { id: '215', date: '14-Feb-24', weight: 116, reps: 5, video: 'No' },
    { id: '220', date: '19-Feb-24', weight: 120, reps: 5, video: 'Yes' },
    { id: '229', date: '28-Feb-24', weight: 120, reps: 5, video: 'No' },
    { id: '235', date: '4-Mar-24', weight: 120, reps: 5, video: 'Yes' },
    { id: '239', date: '8-Mar-24', weight: 120, reps: 5, video: 'Yes' },
    { id: '242', date: '11-Mar-24', weight: 120, reps: 5, video: 'No' },
    { id: '246', date: '15-Mar-24', weight: 125, reps: 5, video: 'Yes' },
    { id: '249', date: '18-Mar-24', weight: 125, reps: 5, video: 'Yes' },
    { id: '263', date: '1-Apr-24', weight: 125, reps: 5, video: 'No' },
    { id: '304', date: '13-May-24', weight: 125, reps: 5, video: 'No' },
    { id: '371', date: '19-Jul-24', weight: 130, reps: 5, video: 'No' },
];

export default PBsData;