import React, { useEffect, useState } from 'react';
import { useViewportScaling } from '../../hooks/useViewportScaling';

const BloodTestsTable = () => {
  const { scale, scaleW, scaleH } = useViewportScaling();
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    // Delay rendering until after initial layout
    requestAnimationFrame(() => {
      setIsLoaded(true);
    });
  }, []);

  const bloodTestsData = [
    {
      category: "Red Blood Cells",
      tests: [
        { name: "Haemoglobin", unit: "g/L", siphox1: "", siphox2: "", medichecks: "153" },
        { name: "Haematocrit", unit: "L/L", siphox1: "", siphox2: "", medichecks: "0.447" },
        { name: "Red Cell Count", unit: "/L", siphox1: "", siphox2: "", medichecks: "4.8 · 10^12" },
        { name: "MCV", unit: "fL", siphox1: "", siphox2: "", medichecks: "93.1" },
        { name: "MCH", unit: "pg", siphox1: "", siphox2: "", medichecks: "31.9" },
        { name: "MCHC", unit: "g/L", siphox1: "", siphox2: "", medichecks: "343" },
        { name: "RDW", unit: "%", siphox1: "", siphox2: "", medichecks: "12.6" },
      ],
      position: { left: 2360 * scaleW, top: 500 * scaleH }
    },
    {
      category: "White Blood Cells",
      tests: [
        { name: "White Cell Count", unit: "/L", siphox1: "", siphox2: "", medichecks: "5.1 · 10^9" },
        { name: "Neutrophils", unit: "/L", siphox1: "", siphox2: "", medichecks: "1.7 · 10^9" },
        { name: "Lymphocytes", unit: "/L", siphox1: "", siphox2: "", medichecks: "2.7 · 10^9" },
        { name: "Monocytes", unit: "/L", siphox1: "", siphox2: "", medichecks: "0.5 · 10^9" },
        { name: "Eosinophils", unit: "/L", siphox1: "", siphox2: "", medichecks: "0.1 · 10^9" },
        { name: "Basophils", unit: "/L", siphox1: "", siphox2: "", medichecks: "0.0 · 10^9" },
      ],
      position: { left: 2360 * scaleW, top: 940 * scaleH }
    },
    {
      category: "Clotting Status",
      tests: [
        { name: "Platelet Count", unit: "/L", siphox1: "", siphox2: "", medichecks: "157 · 10^9" },
        { name: "MPV", unit: "fL", siphox1: "", siphox2: "", medichecks: "9.3" },
      ],
      position: { left: 2360 * scaleW, top: 1350 * scaleH }
    },
    {
      category: "Kidney Health",
      tests: [
        { name: "Urea", unit: "mmol/L", siphox1: "", siphox2: "", medichecks: "4.4" },
        { name: "Creatinine", unit: "umol/L", siphox1: "", siphox2: "", medichecks: "130" },
        { name: "eGFR", unit: "ml/min/1.73m^2", siphox1: "", siphox2: "", medichecks: "69" },
      ],
      position: { left: 1480 * scaleW, top: 1770 * scaleH }
    },
    {
      category: "Liver Health",
      tests: [
        { name: "Bilirubin", unit: "umol/L", siphox1: "", siphox2: "", medichecks: "8.7" },
        { name: "ALP", unit: "U/L", siphox1: "", siphox2: "", medichecks: "98" },
        { name: "ALT", unit: "U/L", siphox1: "", siphox2: "", medichecks: "19" },
        { name: "GGT", unit: "U/L", siphox1: "", siphox2: "", medichecks: "7" },
      ],
      position: { left: 1480 * scaleW, top: 1440 * scaleH }
    },
    {
      category: "Proteins",
      tests: [
        { name: "Total Protein", unit: "g/L", siphox1: "", siphox2: "", medichecks: "73" },
        { name: "Albumin", unit: "g/L", siphox1: "", siphox2: "", medichecks: "48" },
        { name: "Globulin", unit: "g/L", siphox1: "", siphox2: "", medichecks: "25" },
        { name: "SHBG", unit: "nmol/L", siphox1: "", siphox2: "", medichecks: "36.9" },
      ],
      position: { left: 3340 * scaleW, top: 500 * scaleH }
    },
    {
      category: "Diabetes",
      tests: [
        { name: "HbA1c", unit: "mMol/Mol", siphox1: "34.4", siphox2: "33.3", medichecks: "34" },
        { name: "Fasting Insulin", unit: "uIU/mL", siphox1: "6.72", siphox2: "9.96", medichecks: "" },
      ],
      position: { left: 1480 * scaleW, top: 1180 * scaleH }
    },
    {
      category: "Cholesterol Status",
      tests: [
        { name: "Total Cholesterol", unit: "mmol/L", siphox1: "2.59", siphox2: "3.18", medichecks: "4.25" },
        { name: "LDL Cholesterol", unit: "mmol/L", siphox1: "1.24", siphox2: "1.45", medichecks: "2.22" },
        { name: "Non HDL Cholesterol", unit: "mmol/L", siphox1: "", siphox2: "", medichecks: "2.66" },
        { name: "HDL Cholesterol", unit: "mmol/L", siphox1: "1.34", siphox2: "1.73", medichecks: "1.59" },
        { name: "Total Cholesterol : HDL Ratio", unit: "Ratio", siphox1: "2.17", siphox2: "1.94", medichecks: "2.67" },
        { name: "Triglycerides", unit: "mmol/L", siphox1: "0.7458", siphox2: "0.4068", medichecks: "0.97" },
        { name: "Triglyceride : HDL Ratio", unit: "Ratio", siphox1: "1.26", siphox2: "0.53", medichecks: "0.61" },
        { name: "Apolipoprotein A1 (APOA1)", unit: "g/L", siphox1: "1.38", siphox2: "1.55", medichecks: "1.59" },
        { name: "Apolipoprotein B (APOB)", unit: "g/L", siphox1: "0.64", siphox2: "0.71", medichecks: "0.72" },
        { name: "Lipoprotein(a)", unit: "nmol/L", siphox1: "", siphox2: "", medichecks: "<7" },
        { name: "ApoB : ApoA1 Ratio", unit: "ratio", siphox1: "0.46", siphox2: "0.45", medichecks: "0.45" },
      ],
      position: { left: 1480 * scaleW, top: 500 * scaleH }
    },
    {
      category: "Inflammation",
      tests: [
        { name: "CRP HS (High-Sensitivity CRP)", unit: "mg/L", siphox1: "<0.20", siphox2: "0.63", medichecks: "<0.15" },
        { name: "Homocysteine (HCY)", unit: "umol/L", siphox1: "7.4", siphox2: "3.1", medichecks: "" },
      ],
      position: { left: 600 * scaleW, top: 1310 * scaleH }
    },
    {
      category: "Gout Risk",
      tests: [
        { name: "Uric Acid", unit: "umol/L", siphox1: "", siphox2: "", medichecks: "286" },
      ],
      position: { left: 600 * scaleW, top: 1600 * scaleH }
    },
    {
      category: "Iron Status",
      tests: [
        { name: "Iron", unit: "umol/L", siphox1: "", siphox2: "", medichecks: "17.7" },
        { name: "TIBC", unit: "umol/L", siphox1: "", siphox2: "", medichecks: "51.8" },
        { name: "UIBC", unit: "umol/L", siphox1: "", siphox2: "", medichecks: "34.1" },
        { name: "Transferrin Saturation", unit: "%", siphox1: "", siphox2: "", medichecks: "34.2" },
        { name: "Ferritin", unit: "ug/L", siphox1: "85.5", siphox2: "63.9", medichecks: "150" },
      ],
      position: { left: 3340 * scaleW, top: 1200 * scaleH }
    },
    {
      category: "Vitamins",
      tests: [
        { name: "Folate - Serum", unit: "nmol/L", siphox1: "", siphox2: "", medichecks: "22.2" },
        { name: "Vitamin B12 - Active", unit: "pmol/L", siphox1: "", siphox2: "", medichecks: "91.1" },
        { name: "Vitamin D", unit: "nmol/L", siphox1: "", siphox2: "", medichecks: "99.4" },
        { name: "Vitamin D (25-(OH) Vitamin D)", unit: "nmol/L", siphox1: "71.8", siphox2: "56.6", medichecks: "" },
      ],
      position: { left: 3340 * scaleW, top: 830 * scaleH }
    },
    {
      category: "Thyroid Hormones",
      tests: [
        { name: "TSH (Thyroid Stimulating Hormone)", unit: "mIU/L", siphox1: "1.64", siphox2: "1.14", medichecks: "2.33" },
        { name: "Free T3", unit: "pmol/L", siphox1: "", siphox2: "", medichecks: "4.7" },
        { name: "Free Thyroxine", unit: "pmol/L", siphox1: "", siphox2: "", medichecks: "18.5" },
      ],
      position: { left: 600 * scaleW, top: 1000 * scaleH }
    },
    {
      category: "Hormones",
      tests: [
        { name: "FSH", unit: "IU/L", siphox1: "", siphox2: "", medichecks: "3.6" },
        { name: "LH", unit: "IU/L", siphox1: "", siphox2: "", medichecks: "6.6" },
        { name: "Oestradiol", unit: "pmol/L", siphox1: "", siphox2: "", medichecks: "103" },
        { name: "Testosterone (Total)", unit: "nmol/L", siphox1: "15.3", siphox2: "17.1", medichecks: "17.00" },
        { name: "Free Testosterone - Calc.", unit: "nmol/L", siphox1: "", siphox2: "", medichecks: "0.316" },
        { name: "Dehydroepiandrosterone Sulfate (DHEA-S)", unit: "ug/dL", siphox1: "337", siphox2: "400", medichecks: "" },
        { name: "Morning Cortisol", unit: "ug/dL", siphox1: "13.6", siphox2: "7.3", medichecks: "" },
      ],
      position: { left: 600 * scaleW, top: 500 * scaleH }
    },
    {
      category: "Autoimmunity",
      tests: [
        { name: "Thyroglobulin Antibodies", unit: "kIU/L", siphox1: "", siphox2: "", medichecks: "17.4" },
        { name: "Thyroid Peroxidase Antibodies", unit: "kIU/L", siphox1: "", siphox2: "", medichecks: "<9" },
      ],
      position: { left: 2360 * scaleW, top: 1600 * scaleH }
    },
  ];

  const tableStyle = {
    width: `${800 * scale}px`,
    borderCollapse: 'separate',
    borderSpacing: 0,
    transform: 'translate(-50%, 0%)',
    color: '#CCCCCC',
  };

  const cellStyle = {
    padding: `${3 * scale}px ${10 * scale}px`,
    textAlign: 'left',
    fontSize: `${20 * scale}px`,
  };

  const subheadingStyle = {
    fontWeight: 'bold',
    paddingTop: `${25 * scale}px`,
    paddingBottom: `${20 * scale}px`,
    paddingLeft: `${10 * scale}px`,
    textAlign: 'left',
  };

  const contentCellStyle = {
    ...cellStyle,
    borderTop: '1px solid rgba(204, 204, 204, 0.5)',
  };

  const headerStyle = {
    ...cellStyle,
    fontWeight: 'bold',
    whiteSpace: 'pre-line',
    fontSize: `${20 * scale}px`,
  };

  const columnWidths = {
    name: '30%',
    unit: '25%',
    result: '15%',
  };

  if (!isLoaded) {
    return null; // Return null or a loading indicator
  }

  return (
    <div style={{ 
      color: '#CCCCCC', 
      position: 'absolute', 
      width: '100%', 
      height: '100%', 
      top: 0, 
      left: 0, 
      fontFamily: 'Arial, sans-serif',
      overflow: 'hidden' // Prevent scrollbars
    }}>
      <h2 className="text-2xl font-bold mb-4" style={{ 
        position: 'absolute', 
        top: `${200 * scaleH}px`, 
        left: `${2500 * scaleW}px`, 
        zIndex: 10, 
        fontSize: `${30 * scale}px`,
        border: '2px solid #CCCCCC',
        padding: `${10 * scale}px`,
        borderRadius: `${5 * scale}px`
      }}>
        <p>18-Jan-2024: Lance Fingerstick Test, Siphox Health</p>
        <p>08-Apr-2024: Lance Fingerstick Test, Siphox Health</p>
        <p>17-Jul-2024: Venous Blood Draw, Medichecks</p>
      </h2>
      {bloodTestsData.map((category, index) => (
        <div key={index} style={{ position: 'absolute', left: category.position.left, top: category.position.top }}>
          <table style={{...tableStyle, margin: 0, padding: 0}}>
            <thead>
              <tr>
                <th colSpan="5" style={{...subheadingStyle, margin: 0}}>{category.category}</th>
              </tr>
              <tr>
                <th style={{...headerStyle, width: columnWidths.name}}></th>
                <th style={{...headerStyle, width: columnWidths.unit}}></th>
                <th style={{...headerStyle, width: columnWidths.result}}>18-Jan-2024</th>
                <th style={{...headerStyle, width: columnWidths.result}}>08-Apr-2024</th>
                <th style={{...headerStyle, width: columnWidths.result}}>17-Jul-2024</th>
              </tr>
            </thead>
            <tbody>
              {category.tests.map((test, testIndex) => (
                <tr key={testIndex}>
                  <td style={{...contentCellStyle, margin: 0}}>{test.name}</td>
                  <td style={{...contentCellStyle, margin: 0}}>{test.unit}</td>
                  <td style={{...contentCellStyle, margin: 0}}>{test.siphox1}</td>
                  <td style={{...contentCellStyle, margin: 0}}>{test.siphox2}</td>
                  <td style={{...contentCellStyle, margin: 0}}>{test.medichecks}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ))}
    </div>
  );
};

export default BloodTestsTable;