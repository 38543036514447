import React, { useState, useEffect, useRef } from 'react';
import { useViewportScaling } from '../../hooks/useViewportScaling';

const videos = [
  { src: '8-Feb-20-1.mp4', title: '190kg' },
  { src: '8-Feb-20-2.mp4', title: '210kg' },
  { src: '8-Feb-20-3.mp4', title: '215kg' },
  { src: '8-Feb-20-4.mp4', title: '115kg' },
  { src: '8-Feb-20-5.mp4', title: '125kg' },
  { src: '8-Feb-20-6.mp4', title: '127.5kg (miss)' },
  { src: '8-Feb-20-7.mp4', title: '200kg' },
  { src: '8-Feb-20-8.mp4', title: '210kg' },
  { src: '8-Feb-20-9.mp4', title: '220kg' }
];

const Video = ({ video, scale, onEnded, isPlaying }) => {
  const videoRef = useRef(null);

  useEffect(() => {
    if (isPlaying && videoRef.current) {
      videoRef.current.play().catch(e => console.error("Error playing video:", e));
    }
  }, [isPlaying]);

  return (
    <div style={{ textAlign: 'center' }}>
      <video 
        ref={videoRef}
        width={`${300 * scale}px`} 
        height="auto" 
        controls 
        muted 
        playsInline
        onEnded={onEnded}
      >
        <source src={require(`../../media/gym/powerlifting/${video.src}`)} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <div style={{ color: '#CCCCCC', marginTop: '5px' }}>{video.title}</div>
    </div>
  );
};

const CompGrid20201 = () => {
  const { scale } = useViewportScaling();
  const [currentVideoIndex, setCurrentVideoIndex] = useState(0);

  const handleVideoEnded = () => {
    setCurrentVideoIndex((prevIndex) => (prevIndex + 1) % videos.length);
  };

  return (
    <div style={{ display: 'grid', gridTemplateColumns: 'repeat(4, 1fr)', gap: '10px' }}>
      <div></div>
      <div style={{ textAlign: 'center', fontWeight: 'bold', color: '#CCCCCC' }}>Attempt 1</div>
      <div style={{ textAlign: 'center', fontWeight: 'bold', color: '#CCCCCC' }}>Attempt 2</div>
      <div style={{ textAlign: 'center', fontWeight: 'bold', color: '#CCCCCC' }}>Attempt 3</div>
      <div style={{ textAlign: 'center', fontWeight: 'bold', color: '#CCCCCC' }}>Squat</div>
      {videos.slice(0, 3).map((video, index) => (
        <Video 
          video={video} 
          scale={scale} 
          key={index} 
          onEnded={handleVideoEnded}
          isPlaying={currentVideoIndex === index}
        />
      ))}
      <div style={{ textAlign: 'center', fontWeight: 'bold', color: '#CCCCCC' }}>Bench</div>
      {videos.slice(3, 6).map((video, index) => (
        <Video 
          video={video} 
          scale={scale} 
          key={index + 3} 
          onEnded={handleVideoEnded}
          isPlaying={currentVideoIndex === index + 3}
        />
      ))}
      <div style={{ textAlign: 'center', fontWeight: 'bold', color: '#CCCCCC' }}>Deadlift</div>
      {videos.slice(6, 9).map((video, index) => (
        <Video 
          video={video} 
          scale={scale} 
          key={index + 6} 
          onEnded={handleVideoEnded}
          isPlaying={currentVideoIndex === index + 6}
        />
      ))}
    </div>
  );
};

export default CompGrid20201;
