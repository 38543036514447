import { useState, useEffect, useCallback } from 'react';

const REFERENCE_WIDTH = 3840;
const REFERENCE_HEIGHT = 2160;
const REFERENCE_PHONE_WIDTH = 393;
const REFERENCE_PHONE_HEIGHT = 852;

export const useViewportScaling = () => {
  const [viewportScale, setViewportScale] = useState({
    scaleW: 1,
    scaleH: 1,
    scale: 1,
    scalePhoneW: 1,
    scalePhoneH: 1,
    scalePhone: 1,
    isLandscape: true,
    isPhone: false,
  });

  const updateScale = useCallback(() => {
    const vw = window.innerWidth;
    const vh = window.innerHeight;
    const scaleW = vw / REFERENCE_WIDTH;
    const scaleH = vh / REFERENCE_HEIGHT;
    const scale = Math.min(scaleW, scaleH);
    const scalePhoneW = vw / REFERENCE_PHONE_WIDTH;
    const scalePhoneH = vh / REFERENCE_PHONE_HEIGHT;
    const scalePhone = Math.min(scalePhoneW, scalePhoneH);
    const isLandscape = vw > vh;
    const isPhone = vw / vh <= 0.5;

    setViewportScale({ scaleW, scaleH, scale, scalePhoneW, scalePhoneH, scalePhone, isLandscape, isPhone });

    document.documentElement.style.setProperty('--scale-w', scaleW.toString());
    document.documentElement.style.setProperty('--scale-h', scaleH.toString());
    document.documentElement.style.setProperty('--scale', scale.toString());
    document.documentElement.style.setProperty('--scale-phone-w', scalePhoneW.toString());
    document.documentElement.style.setProperty('--scale-phone-h', scalePhoneH.toString());
    document.documentElement.style.setProperty('--scale-phone', scalePhone.toString());
  }, []);

  useEffect(() => {
    updateScale(); 

    const debouncedHandleResize = debounce(updateScale, 100);
    window.addEventListener('resize', debouncedHandleResize);

    return () => {
      window.removeEventListener('resize', debouncedHandleResize);
    };
  }, [updateScale]);

  return viewportScale;
};

// Debounce function to limit the rate of updateScale calls
const debounce = (func, wait) => {
  let timeout;
  return (...args) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => func(...args), wait);
  };
};