import React, { useState, useEffect } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { useViewportScaling } from '../../hooks/useViewportScaling';

const data = [
  { age: 25, perceptualSpeed: 51.5, numericAbility: 52.3, verbalAbility: 50.5, inductiveReasoning: 50.9, verbalMemory: 50.1, spatialOrientation: 48.6 },
  { age: 32, perceptualSpeed: 52.6, numericAbility: 52.0, verbalAbility: 50.0, inductiveReasoning: 50.7, verbalMemory: 49.4, spatialOrientation: 49.8 },
  { age: 39, perceptualSpeed: 52.8, numericAbility: 51.9, verbalAbility: 51.0, inductiveReasoning: 51.2, verbalMemory: 49.9, spatialOrientation: 51.1 },
  { age: 46, perceptualSpeed: 52.5, numericAbility: 51.5, verbalAbility: 51.4, inductiveReasoning: 51.2, verbalMemory: 50.0, spatialOrientation: 51.2 },
  { age: 53, perceptualSpeed: 51.8, numericAbility: 50.8, verbalAbility: 51.7, inductiveReasoning: 51.1, verbalMemory: 50.3, spatialOrientation: 51.1 },
  { age: 60, perceptualSpeed: 50.8, numericAbility: 49.7, verbalAbility: 51.8, inductiveReasoning: 50.6, verbalMemory: 50.3, spatialOrientation: 50.6 },
  { age: 67, perceptualSpeed: 48.9, numericAbility: 47.7, verbalAbility: 51.0, inductiveReasoning: 49.1, verbalMemory: 48.1, spatialOrientation: 49.1 },
  { age: 74, perceptualSpeed: 46.7, numericAbility: 45.3, verbalAbility: 50.2, inductiveReasoning: 47.2, verbalMemory: 46.1, spatialOrientation: 47.5 },
  { age: 81, perceptualSpeed: 43.3, numericAbility: 41.4, verbalAbility: 48.4, inductiveReasoning: 44.3, verbalMemory: 42.6, spatialOrientation: 44.0 },
  { age: 88, perceptualSpeed: 39.9, numericAbility: 37.4, verbalAbility: 44.2, inductiveReasoning: 41.9, verbalMemory: 38.7, spatialOrientation: 40.9 },
];

const CognitiveAgeChart = () => {
  const { scale, scaleW, scaleH } = useViewportScaling();
  const [showPoints, setShowPoints] = useState(true);
  const [showLines, setShowLines] = useState(true);
  const [chartDimensions, setChartDimensions] = useState({ width: 0, height: 0 });
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    const updateDimensions = () => {
      setChartDimensions({
        width: 3000 * scale,
        height: 1000 * scale
      });
    };

    updateDimensions();

    const handleResize = () => {
      updateDimensions();
    };

    window.addEventListener('resize', handleResize);

    // Use requestAnimationFrame to ensure the chart is rendered after the initial layout
    requestAnimationFrame(() => {
      setIsLoaded(true);
    });

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [scale]);

  const lineColors = {
    perceptualSpeed: '#4FC3F7',  // blue
    numericAbility: '#81C784',   // green
    verbalMemory: '#D4E157',     // lime
    verbalAbility: '#FFD54F',    // yellow
    inductiveReasoning: '#FF8A65', // orange
    spatialOrientation: '#E57373' // red
  };

  const chartStyle = {
    position: 'absolute',
    left: `${1920 * scaleW}px`,
    top: `${1080 * scaleH}px`,
    transform: 'translate(-50%, -50%)',
    width: `${chartDimensions.width}px`,
    height: `${chartDimensions.height}px`,
    opacity: isLoaded ? 1 : 0,
    transition: 'opacity 0.3s ease-in-out',
  };

  const checkboxContainerStyle = {
    position: 'absolute',
    right: `${20 * scale}px`,
    top: `${20 * scale}px`,
    zIndex: 1000,
    fontFamily: 'Arial, sans-serif',
    fontSize: `${24 * scale}px`,
    color: '#CCCCCC',
  };

  const checkboxStyle = {
    width: `${30 * scale}px`,
    height: `${30 * scale}px`,
    marginRight: `${10 * scale}px`,
  };

  const labelStyle = {
    display: 'flex',
    alignItems: 'center',
    fontFamily: 'Arial, sans-serif',
    fontSize: `${30 * scale}px`,
    marginBottom: `${30 * scale}px`,
  };

  if (!isLoaded) {
    return null; // Return null or a loading indicator
  }

  return (
    <div style={chartStyle}>
      <div style={checkboxContainerStyle}>
        <label style={labelStyle}>
          <input
            type="checkbox"
            checked={showPoints}
            onChange={() => setShowPoints(!showPoints)}
            style={checkboxStyle}
          />
          <span>Points</span>
        </label>
        <label style={labelStyle}>
          <input
            type="checkbox"
            checked={showLines}
            onChange={() => setShowLines(!showLines)}
            style={checkboxStyle}
          />
          <span>Lines</span>
        </label>
      </div>
      <ResponsiveContainer width="100%" height="100%">
        <LineChart 
          data={data} 
          margin={{ 
            top: 15 * scale, 
            right: 90 * scale, 
            left: 60 * scale, 
            bottom: 15 * scale 
          }}
        >
          <CartesianGrid strokeDasharray="3 3" stroke="#333333" />
          <XAxis
            dataKey="age"
            tick={{ fill: '#CCCCCC' }}
            label={{ value: 'Age', position: 'insideBottom', fill: '#CCCCCC', dy: 10 }}
            padding={{ left: 30, right: 30 }}
          />
          <YAxis
            domain={[35, 55]}
            ticks={[35, 40, 45, 50, 55]}
            tick={{ fill: '#CCCCCC' }}
            label={{ value: 'T-Score Means', angle: -90, position: 'insideLeft', fill: '#CCCCCC', dx: -10, dy: 40 }}
          />
          <Tooltip contentStyle={{ backgroundColor: '#000000', border: '1px solid #CCCCCC' }} />
          <Legend wrapperStyle={{ top: chartDimensions.height, color: '#CCCCCC' }} />
          {Object.entries(lineColors).map(([key, color]) => (
            <Line
              key={key}
              type="monotone"
              dataKey={key}
              stroke={color}
              strokeWidth={2}
              dot={showPoints ? { r: 4, strokeWidth: 2, fill: color } : false}
              activeDot={showPoints ? { r: 6, strokeWidth: 2, fill: color } : false}
              filter="url(#glow)"
              hide={false}
              strokeOpacity={showLines ? 1 : 0}
            />
          ))}
          <defs>
            <filter id="glow">
              <feGaussianBlur stdDeviation="2.5" result="coloredBlur" />
              <feMerge>
                <feMergeNode in="coloredBlur" />
                <feMergeNode in="SourceGraphic" />
              </feMerge>
            </filter>
          </defs>
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

export default CognitiveAgeChart;