import React, { useState, useEffect } from 'react';
import CardioFitnessTable from './CardioFitnessTable';
import PhysicalActivities from './PhysicalActivities';
import VO2MaxChart from './V02MaxChart';
import { useViewportScaling } from '../../hooks/useViewportScaling';

const PageMetabolic = () => {
  const { scale } = useViewportScaling();
  const [isOpen, setIsOpen] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    // Delay rendering to ensure all calculations are done
    const timer = setTimeout(() => {
      setIsLoaded(true);
    }, 100);

    return () => clearTimeout(timer);
  }, []);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const containerStyle = {
    color: '#CCCCCC',
    padding: `${20 * scale}px`,
    opacity: isLoaded ? 1 : 0,
    transition: 'opacity 300ms ease-in-out',
  };

  const dropdownStyle = {
    position: 'absolute',
    width: `${3000 * scale}px`,
    height: `${100 * scale}px`,
    top: `${2100 * scale}px`,
    left: '50%',
    transform: 'translate(-50%, 0)',
    backgroundColor: '#111111',
    color: '#CCCCCC',
    fontSize: `${30 * scale}px`,
    padding: `${20 * scale}px`,
    borderRadius: `${10 * scale}px`,
  };

  const buttonStyle = {
    position: 'absolute',
    width: `${3000 * scale}px`,
    height: `${100 * scale}px`,
    top: `${1950 * scale}px`,
    transform: 'translate(-50%, -50%)',
    fontWeight: 'bold',
    left: '50%',
    padding: `${20 * scale}px`,
    borderRadius: `${10 * scale}px`,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: '#111111',
    color: '#CCCCCC',
    fontSize: `${30 * scale}px`,
  };

  const svgStyle = {
    width: `${40 * scale}px`,
    height: `${40 * scale}px`,
    transition: 'transform 0.2s',
    transform: isOpen ? 'rotate(90deg)' : 'rotate(0deg)',
  };

  if (!isLoaded) {
    return null; // Return null or a loading indicator while content is not ready
  }

  return (
    <div className="container" style={containerStyle}>
      <div style={{ marginBottom: `${40 * scale}px` }}>
        <VO2MaxChart />
      </div>
      <div style={{ marginBottom: `${20 * scale}px`, marginTop: `${20 * scale}px` }}>
        <button
          onClick={toggleDropdown}
          style={buttonStyle}
        >
          <span>Sources</span>
          <svg
            style={svgStyle}
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5l7 7-7 7"></path>
          </svg>
        </button>
      </div>
      {isOpen && (
        <div style={dropdownStyle}>
          <p style={{ fontWeight: 'bold', marginBottom: `${50 * scale}px` }}>
            NOTE: These sources provide MET values, where 1 MET is the resting metabolic rate obtained during quiet sitting. 1 MET equals 3.5 mL/kg per minute of oxygen consumption. This is the conversion I use to convert to VO2 max shown in the chart above.
          </p>
          <CardioFitnessTable />
          <PhysicalActivities />
        </div>
      )}
    </div>
  );
};

export default PageMetabolic;