import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useViewportScaling } from '../../hooks/useViewportScaling';

const DisplayMRI = () => {
  const [currentSagittalIndex, setCurrentSagittalIndex] = useState(0);
  const [currentAxialIndex, setCurrentAxialIndex] = useState(0);
  const [currentAxialT2Index, setCurrentAxialT2Index] = useState(0);
  const [currentAxialT1Index, setCurrentAxialT1Index] = useState(0);
  const [currentSagittalT1SEIndex, setCurrentSagittalT1SEIndex] = useState(0);
  const [currentSagittalT2WSIndex, setCurrentSagittalT2WSIndex] = useState(0);
  const [totalSagittalImages] = useState(13);
  const [totalAxialImages] = useState(40);
  const [totalAxialT2Images] = useState(25);
  const [totalAxialT1Images] = useState(42);
  const [totalSagittalT1SEImages] = useState(23);
  const [totalSagittalT2WSImages] = useState(11);
  const [currentSagittalImage, setCurrentSagittalImage] = useState(null);
  const [currentAxialImage, setCurrentAxialImage] = useState(null);
  const [currentAxialT2Image, setCurrentAxialT2Image] = useState(null);
  const [currentAxialT1Image, setCurrentAxialT1Image] = useState(null);
  const [currentSagittalT1SEImage, setCurrentSagittalT1SEImage] = useState(null);
  const [currentSagittalT2WSImage, setCurrentSagittalT2WSImage] = useState(null);
  const { scale, scaleW, scaleH } = useViewportScaling();
  const [isPlaying, setIsPlaying] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const containerRef = useRef(null);

  useEffect(() => {
    const loadImages = async () => {
      try {
        const sagittalModule = await import(`../../media/mri/sag-t1/sag-t1-${(currentSagittalIndex + 1).toString().padStart(3, '0')}.png`);
        setCurrentSagittalImage(sagittalModule.default);

        const axialModule = await import(`../../media/mri/ax-t1wi/ax-t1wi-${(currentAxialIndex + 1).toString().padStart(3, '0')}.png`);
        setCurrentAxialImage(axialModule.default);

        const axialT2Module = await import(`../../media/mri/ax-t2/ax-t2-${(currentAxialT2Index + 1).toString().padStart(3, '0')}.png`);
        setCurrentAxialT2Image(axialT2Module.default);

        const axialT1Module = await import(`../../media/mri/ax-t1/ax-t1-${(currentAxialT1Index + 1).toString().padStart(3, '0')}.png`);
        setCurrentAxialT1Image(axialT1Module.default);

        const sagittalT1SEModule = await import(`../../media/mri/sag-t1se/sag-t1se-${(currentSagittalT1SEIndex + 1).toString().padStart(3, '0')}.png`);
        setCurrentSagittalT1SEImage(sagittalT1SEModule.default);

        const sagittalT2WSModule = await import(`../../media/mri/sag-t2ws/sag-t2ws-${(currentSagittalT2WSIndex + 5).toString().padStart(3, '0')}.png`);
        setCurrentSagittalT2WSImage(sagittalT2WSModule.default);

        setIsLoading(false);
      } catch (error) {
        console.error('Failed to load image:', error);
        setIsLoading(false);
      }
    };

    loadImages();
  }, [currentSagittalIndex, currentAxialIndex, currentAxialT2Index, currentAxialT1Index, currentSagittalT1SEIndex, currentSagittalT2WSIndex]);

  useEffect(() => {
    const handleResize = () => {
      if (containerRef.current) {
        containerRef.current.style.height = `${window.innerHeight}px`;
      }
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleNextSagittalImage = useCallback(() => {
    setCurrentSagittalIndex((prevIndex) => (prevIndex + 1) % totalSagittalImages);
  }, [totalSagittalImages]);

  const handleNextAxialImage = useCallback(() => {
    setCurrentAxialIndex((prevIndex) => (prevIndex + 1) % totalAxialImages);
  }, [totalAxialImages]);

  const handleNextAxialT2Image = useCallback(() => {
    setCurrentAxialT2Index((prevIndex) => (prevIndex + 1) % totalAxialT2Images);
  }, [totalAxialT2Images]);

  const handleNextAxialT1Image = useCallback(() => {
    setCurrentAxialT1Index((prevIndex) => (prevIndex + 1) % totalAxialT1Images);
  }, [totalAxialT1Images]);

  const handleNextSagittalT1SEImage = useCallback(() => {
    setCurrentSagittalT1SEIndex((prevIndex) => (prevIndex + 1) % totalSagittalT1SEImages);
  }, [totalSagittalT1SEImages]);

  const handleNextSagittalT2WSImage = useCallback(() => {
    setCurrentSagittalT2WSIndex((prevIndex) => (prevIndex + 1) % totalSagittalT2WSImages);
  }, [totalSagittalT2WSImages]);

  const playAll = useCallback(() => {
    setIsPlaying(true);
  }, []);

  const pauseAll = useCallback(() => {
    setIsPlaying(false);
  }, []);

  const resetAll = useCallback(() => {
    setIsPlaying(false);
    setCurrentSagittalIndex(0);
    setCurrentAxialIndex(0);
    setCurrentAxialT2Index(0);
    setCurrentAxialT1Index(0);
    setCurrentSagittalT1SEIndex(0);
    setCurrentSagittalT2WSIndex(0);
  }, []);

  useEffect(() => {
    let intervalId;
    if (isPlaying) {
      intervalId = setInterval(() => {
        handleNextSagittalImage();
        handleNextAxialImage();
        handleNextAxialT2Image();
        handleNextAxialT1Image();
        handleNextSagittalT1SEImage();
        handleNextSagittalT2WSImage();
      }, 125); // 8 images per second
    }
    return () => clearInterval(intervalId);
  }, [isPlaying, handleNextSagittalImage, handleNextAxialImage, handleNextAxialT2Image, handleNextAxialT1Image, handleNextSagittalT1SEImage, handleNextSagittalT2WSImage]);

  // Common scaled pixel values
  const scaledPixels = {
    five: 5 * scale,
    ten: 10 * scale,
    fifteen: 15 * scale,
    twenty: 20 * scale,
    thirty: 30 * scale,
    oneHundred: 100 * scaleW,
    sixHundred: 600 * scaleW,
    fourHundred: 400 * scaleH,
    eightFifty: 850 * scaleH,
    fourteenEighty: 1480 * scaleW,
    sixteenFifty: 1650 * scaleH,
    twentyThreeSixty: 2360 * scaleW,
    threeThousandThreeForty: 3340 * scaleW,
  };

  const imageHeight = 600 * scale;
  const imageWidth = imageHeight * 0.92;
  const axialImageSize = 600 * scale;
  const axialT2ImageSize = 600 * scale;
  const axialT2ImageWidth = axialT2ImageSize * 0.958;
  const axialT1ImageSize = 600 * scale;
  const axialT1ImageWidth = axialT1ImageSize * 1.595;
  const sagittalT1SEImageSize = 600 * scale;
  const sagittalT2WSImageSize = 1200 * scale;

  const headerFontSize = 30 * scale;
  const smallFontSize = 20 * scale;

  if (isLoading) {
    return (
      <div ref={containerRef} style={{ width: '100%', height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: '#000' }}>
        <p style={{ color: '#CCCCCC', fontSize: '24px' }}>Loading...</p>
      </div>
    );
  }

  return (
    <div ref={containerRef} className="image-container" style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', color: '#CCCCCC', fontFamily: 'Arial, sans-serif', overflow: 'hidden' }}>
      <h2 className="text-2xl font-bold mb-4" style={{ 
          position: 'absolute', 
          top: `${200 * scaleH}px`, 
          left: `${2500 * scaleW}px`, 
          zIndex: 10, 
          fontSize: `${30 * scale}px`,
          border: '2px solid #CCCCCC',
          padding: `${10 * scale}px`,
          borderRadius: `${5 * scale}px`
        }}>
          20-Aug-2024<br />
          TOSHIBA Titan MRI Scanner, 1.5T<br />
          Vista Health
        </h2>
        <div className="flex">
          {/* Play All, Pause, and Reset buttons */}
          <div style={{
            position: 'absolute',
            top: `${scaledPixels.eightFifty}px`,
            left: `${scaledPixels.twentyThreeSixty}px`,
            transform: 'translate(-50%, -50%)',
            zIndex: 10,
          }}>
            <button onClick={playAll} style={{ fontSize: `${headerFontSize}px`, marginRight: `${scaledPixels.thirty}px` }}>Play All</button>
            <button onClick={pauseAll} style={{ fontSize: `${headerFontSize}px`, marginRight: `${scaledPixels.thirty}px` }}>Pause</button>
            <button onClick={resetAll} style={{ fontSize: `${headerFontSize}px` }}>Reset</button>
          </div>

          {/* Axial T2 Image Section */}
          {currentAxialT2Image && (
            <div style={{
              position: 'absolute',
              top: `${scaledPixels.eightFifty}px`,
              left: `${scaledPixels.sixHundred}px`,
              transform: 'translate(-50%, -50%)',
              display: 'inline-block'
            }}>
              <img 
                src={currentAxialT2Image} 
                alt={`MRI Axial T2 Slice ${currentAxialT2Index + 1}`} 
                className="mri-image"
                onClick={handleNextAxialT2Image}
                style={{
                  cursor: 'pointer',
                  background: 'none',
                  height: `${axialT2ImageSize}px`,
                  width: `${axialT2ImageWidth}px`,
                }}
              />
              <div 
                style={{
                  position: 'absolute',
                  top: `${scaledPixels.five}px`,
                  right: `${scaledPixels.five}px`,
                  padding: `${scaledPixels.five}px`,
                  fontSize: `${scaledPixels.thirty}px`,
                  fontWeight: 'bold',
                  zIndex: 10
                }}
              >
                {(currentAxialT2Index + 1).toString().padStart(2, '0')}
              </div>
            </div>
          )}
          <div style={{
            position: 'absolute',
            top: `${scaledPixels.eightFifty + axialT2ImageSize / 2 + scaledPixels.thirty}px`,
            left: `${scaledPixels.sixHundred}px`,
            transform: 'translateX(-50%)',
            textAlign: 'center',
            zIndex: 10,
            fontSize: `${smallFontSize}px`
          }}>
            {currentAxialT2Index + 1} of {totalAxialT2Images}. Click to view next image.
          </div>

          {/* Sagittal T1 Image Section */}
          {currentSagittalImage && (
            <div style={{
              position: 'absolute',
              top: `${scaledPixels.eightFifty}px`,
              left: `${scaledPixels.fourteenEighty}px`,
              transform: 'translate(-50%, -50%)',
              display: 'inline-block'
            }}>
              <img 
                src={currentSagittalImage} 
                alt={`MRI Sagittal T1 Slice ${currentSagittalIndex + 1}`} 
                className="mri-image"
                onClick={handleNextSagittalImage}
                style={{
                  cursor: 'pointer',
                  background: 'none',
                  height: `${imageHeight}px`,
                  width: `${imageWidth}px`,
                }}
              />
              <div 
                style={{
                  position: 'absolute',
                  top: `${scaledPixels.five}px`,
                  right: `${scaledPixels.five}px`,
                  padding: `${scaledPixels.five}px`,
                  fontSize: `${scaledPixels.thirty}px`,
                  fontWeight: 'bold',
                  zIndex: 10
                }}
              >
                {(currentSagittalIndex + 1).toString().padStart(2, '0')}
              </div>
            </div>
          )}
          <div style={{
            position: 'absolute',
            top: `${scaledPixels.eightFifty + imageHeight / 2 + scaledPixels.thirty}px`,
            left: `${scaledPixels.fourteenEighty}px`,
            transform: 'translateX(-50%)',
            textAlign: 'center',
            zIndex: 10,
            fontSize: `${smallFontSize}px`
          }}>
            {currentSagittalIndex + 1} of {totalSagittalImages}. Click to view next image.
          </div>

          {/* Sagittal T2WS Image Section */}
          {currentSagittalT2WSImage && (
            <div style={{
              position: 'absolute',
              top: `${scaledPixels.eightFifty + 350 * scale}px`,
              left: `${scaledPixels.threeThousandThreeForty}px`,
              transform: 'translate(-50%, -50%)',
              display: 'inline-block'
            }}>
              <img 
                src={currentSagittalT2WSImage} 
                alt={`MRI Sagittal T2WS Slice ${currentSagittalT2WSIndex + 1}`} 
                className="mri-image"
                onClick={handleNextSagittalT2WSImage}
                style={{
                  cursor: 'pointer',
                  background: 'none',
                  height: `${sagittalT2WSImageSize}px`,
                  width: `${sagittalT2WSImageSize * 0.457}px`,
                }}
              />
              <div 
                style={{
                  position: 'absolute',
                  top: `${scaledPixels.five}px`,
                  right: `${scaledPixels.five}px`,
                  padding: `${scaledPixels.five}px`,
                  fontSize: `${scaledPixels.thirty}px`,
                  fontWeight: 'bold',
                  zIndex: 10
                }}
              >
                {(currentSagittalT2WSIndex + 1).toString().padStart(2, '0')}
              </div>
            </div>
          )}
          <div style={{
            position: 'absolute',
            top: `${scaledPixels.eightFifty + 350 * scale + sagittalT2WSImageSize / 2 + scaledPixels.thirty}px`,
            left: `${scaledPixels.threeThousandThreeForty}px`,
            transform: 'translateX(-50%)',
            textAlign: 'center',
            zIndex: 10,
            fontSize: `${smallFontSize}px`
          }}>
            {currentSagittalT2WSIndex + 1} of {totalSagittalT2WSImages}. Click to view next image.
          </div>

          {/* Sagittal T1SE Image Section */}
          {currentSagittalT1SEImage && (
            <div style={{
              position: 'absolute',
              top: `${scaledPixels.sixteenFifty}px`,
              left: `${scaledPixels.sixHundred}px`,
              transform: 'translate(-50%, -50%)',
              display: 'inline-block'
            }}>
              <img 
                src={currentSagittalT1SEImage} 
                alt={`MRI Sagittal T1SE Slice ${currentSagittalT1SEIndex + 1}`} 
                className="mri-image"
                onClick={handleNextSagittalT1SEImage}
                style={{
                  cursor: 'pointer',
                  background: 'none',
                  height: `${sagittalT1SEImageSize}px`,
                  width: `${sagittalT1SEImageSize}px`,
                }}
              />
              <div 
                style={{
                  position: 'absolute',
                  top: `${scaledPixels.five}px`,
                  right: `${scaledPixels.five}px`,
                  padding: `${scaledPixels.five}px`,
                  fontSize: `${scaledPixels.thirty}px`,
                  fontWeight: 'bold',
                  zIndex: 10
                }}
              >
                {(currentSagittalT1SEIndex + 1).toString().padStart(2, '0')}
              </div>
            </div>
          )}
          <div style={{
            position: 'absolute',
            top: `${scaledPixels.sixteenFifty + sagittalT1SEImageSize / 2 + scaledPixels.thirty}px`,
            left: `${scaledPixels.sixHundred}px`,
            transform: 'translateX(-50%)',
            textAlign: 'center',
            zIndex: 10,
            fontSize: `${smallFontSize}px`
          }}>
            {currentSagittalT1SEIndex + 1} of {totalSagittalT1SEImages}. Click to view next image.
          </div>

          {/* Axial T1 Image Section */}
          {currentAxialT1Image && (
            <div style={{
              position: 'absolute',
              top: `${scaledPixels.sixteenFifty}px`,
              left: `${scaledPixels.twentyThreeSixty}px`,
              transform: 'translate(-50%, -50%)',
              display: 'inline-block'
            }}>
              <img 
                src={currentAxialT1Image} 
                alt={`MRI Axial T1 Slice ${currentAxialT1Index + 1}`} 
                className="mri-image"
                onClick={handleNextAxialT1Image}
                style={{
                  cursor: 'pointer',
                  background: 'none',
                  height: `${axialT1ImageSize}px`,
                  width: `${axialT1ImageWidth}px`,
                }}
              />
              <div 
                style={{
                  position: 'absolute',
                  top: `${scaledPixels.five}px`,
                  right: `${scaledPixels.five}px`,
                  padding: `${scaledPixels.five}px`,
                  fontSize: `${scaledPixels.thirty}px`,
                  fontWeight: 'bold',
                  zIndex: 10
                }}
              >
                {(currentAxialT1Index + 1).toString().padStart(2, '0')}
              </div>
            </div>
          )}
          <div style={{
            position: 'absolute',
            top: `${scaledPixels.sixteenFifty + axialT1ImageSize / 2 + scaledPixels.thirty}px`,
            left: `${scaledPixels.twentyThreeSixty}px`,
            transform: 'translateX(-50%)',
            textAlign: 'center',
            zIndex: 10,
            fontSize: `${smallFontSize}px`
          }}>
            {currentAxialT1Index + 1} of {totalAxialT1Images}. Click to view next image.
          </div>

          {/* Axial T1WI Image Section */}
          {currentAxialImage && (
            <div style={{
              position: 'absolute',
              top: `${scaledPixels.sixteenFifty}px`,
              left: `${scaledPixels.fourteenEighty}px`,
              transform: 'translate(-50%, -50%)',
              display: 'inline-block'
            }}>
              <img 
                src={currentAxialImage} 
                alt={`MRI Axial T1WI Slice ${currentAxialIndex + 1}`} 
                className="mri-image"
                onClick={handleNextAxialImage}
                style={{
                  cursor: 'pointer',
                  background: 'none',
                  height: `${axialImageSize}px`,
                  width: `${axialImageSize}px`,
                }}
              />
              <div 
                style={{
                  position: 'absolute',
                  top: `${scaledPixels.five}px`,
                  right: `${scaledPixels.five}px`,
                  padding: `${scaledPixels.five}px`,
                  fontSize: `${scaledPixels.thirty}px`,
                  fontWeight: 'bold',
                  zIndex: 10
                }}
              >
                {(currentAxialIndex + 1).toString().padStart(2, '0')}
              </div>
            </div>
          )}
          <div style={{
            position: 'absolute',
            top: `${scaledPixels.sixteenFifty + axialImageSize / 2 + scaledPixels.thirty}px`,
            left: `${scaledPixels.fourteenEighty}px`,
            transform: 'translateX(-50%)',
            textAlign: 'center',
            zIndex: 10,
            fontSize: `${smallFontSize}px`
          }}>
            {currentAxialIndex + 1} of {totalAxialImages}. Click to view next image.
          </div>
        </div>
      </div>
    );
  };

export default DisplayMRI;