import React from 'react';
import PageMetabolic from '../components/metabolic/PageMetabolic';

const Metabolic = () => {
  return (
    <div> {/* Remove padding here */}
      <PageMetabolic />
    </div>
  );
};

export default Metabolic;