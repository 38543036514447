import React from 'react';
import PageCognitive from '../components/cognitive/PageCognitive';

const Cognitive = () => {
  return (
    <div> {/* Remove padding here */}
      <PageCognitive />
    </div>
  );
};

export default Cognitive;