const EveningBodyweightData = [
  { id: '2', date: '16-Jul-23', weight: 72.5 }, // correct according to DateIDs.js
  { id: '4', date: '18-Jul-23', weight: 72.8 },
  { id: '8', date: '22-Jul-23', weight: 72.5 },
  { id: '13', date: '27-Jul-23', weight: 73.1 },
  { id: '35', date: '18-Aug-23', weight: 72.3 },
  { id: '37', date: '20-Aug-23', weight: 73.7 },
  { id: '39', date: '22-Aug-23', weight: 72.8 },
  { id: '61', date: '13-Sep-23', weight: 72.8 },
  { id: '62', date: '14-Sep-23', weight: 72.8 },
  { id: '74', date: '26-Sep-23', weight: 73.5 },
  { id: '81', date: '3-Oct-23', weight: 74.9 },
  { id: '82', date: '4-Oct-23', weight: 75.0 },
  { id: '89', date: '11-Oct-23', weight: 74.6 },
  { id: '102', date: '24-Oct-23', weight: 74.8 },
  { id: '108', date: '30-Oct-23', weight: 74.2 },
  { id: '110', date: '1-Nov-23', weight: 73.2 },
  { id: '120', date: '11-Nov-23', weight: 73.3 },
  { id: '122', date: '13-Nov-23', weight: 74.0 },
  { id: '123', date: '14-Nov-23', weight: 72.2 },
  { id: '125', date: '16-Nov-23', weight: 73.9 },
  { id: '134', date: '25-Nov-23', weight: 73.6 },
  { id: '135', date: '26-Nov-23', weight: 73.3 },
  { id: '137', date: '28-Nov-23', weight: 72.7 },
  { id: '138', date: '29-Nov-23', weight: 72.9 },
  { id: '140', date: '1-Dec-23', weight: 74.5 },
  { id: '172', date: '2-Jan-24', weight: 73.4 },
  { id: '173', date: '3-Jan-24', weight: 73.4 },
  { id: '178', date: '8-Jan-24', weight: 72.8 },
  { id: '201', date: '31-Jan-24', weight: 73.7 },
  { id: '212', date: '11-Feb-24', weight: 76.3 },
  { id: '213', date: '12-Feb-24', weight: 75.1 },
  { id: '215', date: '14-Feb-24', weight: 75.1 },
  { id: '216', date: '15-Feb-24', weight: 75.0 },
  { id: '217', date: '16-Feb-24', weight: 74.3 },
  { id: '220', date: '19-Feb-24', weight: 75.7 }, // correct according to DateIDs.js
  { id: '221', date: '20-Feb-24', weight: 75.5 },
  { id: '230', date: '29-Feb-24', weight: 74.2 },
  { id: '233', date: '3-Mar-24', weight: 75.8 },
  { id: '234', date: '4-Mar-24', weight: 74.3 },
  { id: '235', date: '5-Mar-24', weight: 74.3 },
  { id: '241', date: '11-Mar-24', weight: 75.3 },
  { id: '243', date: '13-Mar-24', weight: 76.2 },
  { id: '244', date: '14-Mar-24', weight: 76.0 },
  { id: '258', date: '28-Mar-24', weight: 75.7 },
  { id: '259', date: '29-Mar-24', weight: 74.4 },
  { id: '268', date: '7-Apr-24', weight: 77.4 },
  { id: '269', date: '8-Apr-24', weight: 75.4 },
  { id: '270', date: '9-Apr-24', weight: 73.8 },
  { id: '271', date: '10-Apr-24', weight: 75.7 },
  { id: '272', date: '11-Apr-24', weight: 76.4 },
  { id: '276', date: '15-Apr-24', weight: 74.7 },
  { id: '281', date: '20-Apr-24', weight: 74.1 },
  { id: '297', date: '6-May-24', weight: 75.5 },
  { id: '298', date: '7-May-24', weight: 75.1 },
  { id: '299', date: '8-May-24', weight: 74.0 },
  { id: '303', date: '12-May-24', weight: 75.8 },
  { id: '304', date: '13-May-24', weight: 76.2 },
  { id: '352', date: '30-Jun-24', weight: 75.9 },
  { id: '363', date: '11-Jul-24', weight: 74.7 },
  { id: '394', date: '11-Aug-24', weight: 74.2 },
  { id: '401', date: '18-Aug-24', weight: 74.6 },
  { id: '416', date: '2-Sep-24', weight: 72.3 },
  { id: '417', date: '3-Sep-24', weight: 72.0 },
  { id: '418', date: '4-Sep-24', weight: 73.6 },
  { id: '430', date: '16-Sep-24', weight: 73.3 },
];

export default EveningBodyweightData;